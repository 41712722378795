
<site-header></site-header>
<div *ngIf="loading" _ngcontent-hee-c0="" class="ng-star-inserted" style="
  background-color: rgba(0,0,0,0.5);
  z-index: 9999;
  float: left;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  color: #000;
"><span ngcontent-hee-c0="" class="text"><img ngcontent-hee-c0="" class="custom-class ng-star-inserted" src="assets/loader1.gif"><span class="text1">Please Wait.....</span></span></div>
<div class="page-container">
  <div class="page-content-wrapper">
        <div class="page-content">
            <div class="page-bar">
                <div class="page-title-breadcrumb">
                    <div class=" pull-left">
                        <div class="page-title">Dashboard</div>
                    </div>
                    <ol class="breadcrumb page-breadcrumb pull-right">
                        <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item"
                              >Home</a>&nbsp;<i class="fa fa-angle-right"></i>
                        </li>
                        <li class="active">Dashboard</li>
                    </ol>
                </div>
            </div>
         
            <div class="state-overview">
                <div class="row">
                    <div class="col-sm-12">
                <div class="card">
                    <div class="row">
                    <div class="date">
                    <ul>
                    <span class="date_cal"><input type="date" name="date" value="{{latest_date}}" (change)="dateChange($event)"
                     >
                    </span>
                    <span class="occupied" style="color: black;">Occupied <i class="fa fa-circle"></i></span>
                    <span class="occupied" style="color: black;">Reserved <i class="fa fa-circle rsv_cls"></i></span>
                    <!-- <span class="occupied">Reservation Blocked <i
                    class="fa fa-circle rscblk_cls"></i></span> -->
                    <span class="occupied"  style="color: black;">Dirty <i class="fa fa-circle drt_cls"></i></span>
                    <span class="occupied" style="color: black;">Blocked <i class="fa fa-circle blk_cls"></i></span>
                    <span class="occupied" style="color: black;">Maintenance <i
                    class="fa fa-circle mnt_cls"></i></span>

                    <!-- <span class="occupied" style="color: black;">Last Sync at: <span class="updated_cls">{{updated}}</span> </span>
                    <span class="occupied" style="color: black;" *ngIf="synchbutton"><button class="sync_cls" (click)="sync($event)">Sync Now</button></span> -->
                    </ul>
                    </div>
                    
                    </div>
                    <div class="card-body clss">
                <div class="row equal_width">
                    <div class="col-xl-2 col-md-6 col-12" style="cursor: pointer;" (click)="reportpage($event)">
                        <div class="info-box bg-blue">
                            <span class="info-box-icon push-bottom"><i class="material-icons">style</i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Occupied</span>
                                <span class="info-box-number">{{checkin_count?checkin_count:0}}</span>
                                <div class="progress">
                                    <!-- <div class="progress-bar width-60"></div> -->
                                </div>
                                <span class="progress-description">
                                    <!-- 60% Increase in 28 Days -->
                                </span>
                            </div>
                            
                        </div>
                       
                    </div>
                 
                    <div class="col-xl-2 col-md-6 col-12" style="cursor: pointer;" (click)="reportpage1($event)">
                        <div class="info-box bg-orange">
                            <span class="info-box-icon push-bottom"><i
                                    class="material-icons">card_travel</i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Reserved</span>
                                <span class="info-box-number">{{reservation_count?reservation_count:0}}</span>
                                <div class="progress">
                                    <!-- <div class="progress-bar width-40"></div> -->
                                </div>
                                <span class="progress-description">
                                    <!-- 40% Increase in 28 Days -->
                                </span>
                            </div>
                       
                        </div>
                     
                    </div>
                    <div class="col-xl-2 col-md-6 col-12">
                        <div class="info-box bg-red">
                            <span class="info-box-icon push-bottom"><i
                                    class="material-icons">report_problem</i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Dirty &nbsp;</span>
                                <span class="info-box-number">{{dirty_count?dirty_count:0}}</span>
                                <div class="progress">
                                    <!-- <div class="progress-bar width-60"></div> -->
                                </div>
                                <span class="progress-description">
                                    <!-- 60% Increase in 28 Days -->
                                </span>
                            </div>
                        
                        </div>
                     
                    </div>
                    <div class="col-xl-2 col-md-6 col-12">
                        <div class="info-box bg-purple">
                            <span class="info-box-icon push-bottom"><i
                                    class="material-icons">block</i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Blocked </span>
                                <span class="info-box-number">{{block_count?block_count:0}}</span>
                                <div class="progress">
                                    <!-- <div class="progress-bar width-80"></div> -->
                                </div>
                                <span class="progress-description">
                                    <!-- 80% Increase in 28 Days -->
                                </span>
                            </div>
                            
                        </div>
                     
                    </div>
           
                    <div class="col-xl-2 col-md-6 col-12">
                        <div class="info-box bg-yellow">
                            <span class="info-box-icon push-bottom"><i
                                    class="material-icons">report_problem</i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Maintenance</span>
                                <span class="info-box-number">{{maintanance_count?maintanance_count:0}}</span>
                                <div class="progress">
                                    <!-- <div class="progress-bar width-60"></div> -->
                                </div>
                                <span class="progress-description">
                                    <!-- 60% Increase in 28 Days -->
                                </span>
                            </div>
                        
                        </div>
                     
                    </div>
                  
                </div>


                



                </div>
                </div>
                </div>
                </div>
            </div>
       
         
        </div>
    </div>

    <site-footer></site-footer>
</div>
