import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ModalService, RoomService } from '../_services';
declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css']
})
export class BookingDetailsComponent implements OnInit {
  id: any;
  rmno_data: any;
  token: string;
  data: any;
  media_domain = `${environment.media_domain}`;
  status: any;
  refid: string;
  rejectForm: FormGroup;
  submitted:boolean=false;
  pendingstatus: any;

  constructor(private roomSer: RoomService,
    private formBuilder: FormBuilder,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService, private spinner: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.spinner.show()
    this.id = this.route.snapshot.queryParamMap.get('id');
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.refid = this.route.snapshot.queryParamMap.get('refid');

    this.roomSer.getregistereddata(this.id, this.token,this.refid).subscribe((data) => {
      this.spinner.hide()
      this.data = data.data;
      this.status=data.data.approved_status
    });
    this.rejectForm = this.formBuilder.group({
      reason:['',Validators.required]
    })
}
get f() { return this.rejectForm.controls; }

changestatus(status:any)
{
  this.pendingstatus=status;
  if(status==2){
  $("#myModal").modal("hide");
  $('#rejectModal').modal("hide");
  $('#myModal4').modal('show')
  }else{
  this.roomSer.approveregistration(this.id, this.token,this.refid,status,'').subscribe((data) => {
    this.data = data.data;
    $("#myModal").modal("hide");
    $('#rejectModal').modal("hide");
    if(status==1){
      $('#myModal2').modal('show')
    }
    
    if(status==2){
      $('#myModal3').modal('show')
      }
  });
  }
}

submit()
{
  if(this.rejectForm.invalid){
    this.submitted=true;
    return
  }
   this.roomSer.approveregistration(this.id, this.token,this.refid,this.pendingstatus,this.f.reason.value).subscribe((data) => {
    this.data = data.data;
    $("#myModal4").modal("hide");
    this.rejectForm.reset()
    this.submitted=false
    // $('#rejectModal').modal("hide");
    if(this.pendingstatus==1){
      $('#myModal2').modal('show')
    }
    
    if(this.pendingstatus==2){
      $('#myModal3').modal('show')
      }
  });
}

close1()
{
  open('https://admin-guesthouse.manipal.edu/', '_self').close();
}

cancel()
{
  this.rejectForm.reset()
  this.submitted=false
}



}
