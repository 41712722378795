<site-header></site-header>

<div class="page-container flt">
    <div class="page-content-wrapper">
        <div class="page-content">
           <div class="page-bar">
              <div class="page-title-breadcrumb">
                 <div class="card-head">
                    <div class="page-title">Create User</div>
                 </div>
              </div>
           </div>
   
    <div class="page_content flt">
        <div class="row">
            <div class="col-sm-3">
               
                <div class="card-box flt">
                    <div class="card-body flt">
                    <form [formGroup]="userForm" #myForm="ngForm" (ngSubmit)="onSubmit()">
                            <div
                            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                            <select class="mdl-textfield__input" id="txtRoomNo" name="gender" (change)="selectchange($event)"
                                formControlName="department"
                                [ngClass]="{ 'is-invalid': (f.department.touched || submitted) && f.department.errors }"
                                placeholder="Gender" autocomplete="off">
                                <option value="">Select Department</option>
                                <ng-container>
                                    <option value="2">
                                        Hr
                                    </option>
                                    <option value="1">
                                        Student 
                                    </option>
                                </ng-container>
                            </select>
                            <label class="mdl-textfield__label">Department</label>
                            <div *ngIf="(f.department.touched || submitted) && f.department.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.department.errors?.required">Department is required</div>
                            </div>
                        </div>
                        <div
                            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width" *ngIf="department==1">
                            <select class="mdl-textfield__input" id="txtRoomNo" name="gender"
                                formControlName="institution" 
                                [ngClass]="{ 'is-invalid': (f.institution.touched || submitted) && f.institution.errors }"
                                placeholder="Gender" autocomplete="off">
                                <option value="">Select Institution</option>
                                <ng-container *ngFor="let ins of $institute; let x=index">
                                    <option [value]="ins.institute_code">
                                        {{ins.institute_name}}
                                    </option>
                                </ng-container>
                            </select>
                            <label class="mdl-textfield__label">Institution</label>
                            <div *ngIf="(f.institution.touched || submitted) && f.institution.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.institution.errors?.required">Institution is required</div>
                            </div>
                        </div>
                       
                        <div class="form-group flt">
                            <div
                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                <input class="mdl-textfield__input" type="text" formControlName="name" id="txtRateType" autocomplete="off" [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }">
                                <label class="mdl-textfield__label">Name</label>
                            </div>
                            <div class="invalid">
                                <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.name.errors?.required">Name is required</div>
                            </div>
                            </div>
                        </div>
                        <div class="form-group flt">
                            <div
                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                <input class="mdl-textfield__input" type="text" formControlName="mobile_number" id="txtRateType" autocomplete="off" [ngClass]="{ 'is-invalid': (f.mobile_number.touched || submitted) && f.mobile_number.errors }">
                                <label class="mdl-textfield__label">Mobile Number</label>
                            </div>
                            <div class="invalid">
                                <div *ngIf="(f.mobile_number.touched || submitted) && f.mobile_number.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.mobile_number.errors?.required">Mobile Number is required</div>
                            </div>
                            </div>
                        </div>
                        <div class="form-group flt">
                            <div
                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                <input class="mdl-textfield__input" type="text" formControlName="email" id="txtRateType" autocomplete="off" [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors }">
                                <label class="mdl-textfield__label">Email</label>
                            </div>
                            <div class="invalid">
                                <div *ngIf="(f.email.touched || submitted) && f.email.errors?.required"
                                class="invalid-feedback">
                                <div *ngIf="f.email.errors?.required">Email is required</div>
                            </div>
                            <div *ngIf="(f.email.touched || submitted) && f.email.errors?.email"
                            class="invalid-feedback">
                            <div *ngIf="f.email.errors?.email">Please enter
                                valid email</div>
                        </div>
                            </div>
                        </div>
                    </form>
                </div>
                </div>

                <div class="form_submit flt">
                    <a (click)="onSubmit()">Submit</a>
                    <a class="cancel_cls" (click)="cancel()" *ngIf="isEdit">Cancel</a>
                </div>
            </div>
            <div class="col-sm-9">
                <div class="form_content flt">
                    <div class="form_table table-responsive flt" >
                            <div class="convocation_filter flt">
                                <div class="convacation_filter_head flt"></div>
                                <div >
                                  <div class="row">
                                    <div class="col-sm-5">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text" autocomplete="off" (keyup)="search($event)">
                                                <label class="mdl-textfield__label">Serch</label>
                                            </div>
                                        </div>
                                    <div class="col-sm-5">
                                        <div
                                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                        <select class="mdl-textfield__input institute_select" id="txtRoomNo" name="gender"
                                            formControlName="institution"
                                            placeholder="Gender" autocomplete="off">
                                            <option value="">Select Institution</option>
                                            <ng-container *ngFor="let ins of $institute; let x=index">
                                                <option [value]="ins.institute_code">
                                                    {{ins.institute_name}}
                                                </option>
                                            </ng-container>
                                        </select>
                                        <label class="mdl-textfield__label">Institution</label>
                                       
                                    </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="reset_btn flt">
                                    <button >Reset</button>
                                  </div>
                                    </div>
                                  </div>
                                  
                                </div>
                              </div>
                       

                          <div class="table-responsive">
                            <table class="table display product-overview mb-30">
                               <thead style="background-color: #167CE4; color: white;">
                                  <tr class="mdl-data-table__cell--non-numeric">
                                    <th>SI. No</th>
                                    <th>Institution</th>
                                    <th>Name</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Action</th>
                                  </tr>
                               </thead>
    
    
                               <tbody>
                                <tr *ngFor="let list of $user | paginate : {
                                    itemsPerPage: pageSize,
                                    currentPage: cur_page,
                                    totalItems: totalcount}; let x=index">
                                    <td>{{ perPage * (cur_page - 1) + x + 1 }}</td>
                                    <td>{{list.institute_code}}</td>
                                    <td>{{list.name}}</td>
                                    <td>{{list.mobile_number}}</td>
                                    <td>{{list.email}}</td>
                                    <td> <a (click)="deleteUser($event,list)"><span
                                        class="material-icons action_cls">
                                        delete_outline
                                     </span></a>
                                  <a (click)="editUser($event,list)"><span class="material-icons action_cls">
                                        mode_edit
                                     </span></a>
                               </td>
                                </tr>
    
    
                               </tbody>
                            </table>
                         </div>
                            <div>
                                <p>Showing {{pagingCounter}} to {{cur_page*10}} of {{totalcount}} entries</p>
                            </div>
    
                            <pagination-controls previousLabel="Prev" nextLabel="Next" *ngIf="$user && $user.length"
                            responsive="true" (pageChange)="changePage($event)"></pagination-controls>
                        <br>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>

