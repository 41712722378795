import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {RoomService} from '../_services/room.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
  $institute: any;
  userForm:FormGroup;
  submitted:boolean=false;
  isEdit: boolean=false;
  loading:boolean=false;
  cur_page = 1;
  perPage = 10;
  totalPages = 10;
  count = 0;
  pageSize = 10;
  fileType: any;
  pagingCounter = 0;
  totalcount = 0;
  search_txt: any;
  $user: any;
  department: any;
  regId: any;

  constructor( private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private RoomService:RoomService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.RoomService.getInstitution().subscribe(data => {
      this.$institute=data.data.docs;
  });
  this.userForm = this.formBuilder.group({
    department:['',Validators.required],
    institution: [''],
    name: ['', [Validators.required]],
    mobile_number: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });

  this.RoomService.getInsuser(this.cur_page, this.search_txt ? this.search_txt : '', '').subscribe(data => {
    this.$user = data.data.docs;
    this.cur_page = data.data.page;
    this.totalPages = data.data.totalPages;
    this.totalcount = data.data.totalDocs;
    this.pagingCounter = data.data.pagingCounter;
  });

  }
  get f() { return this.userForm.controls; }

  selectchange(event)
  {
    this.department=event.target.value;
    console.log(event.target.value)
    if(event.target.value=="1")
    {
      this.userForm.controls["institution"].setValidators([Validators.required]);
      this.userForm.controls["institution"].updateValueAndValidity();
    }else{
      this.userForm.controls["institution"].clearValidators();
        this.userForm.controls["institution"].updateValueAndValidity();

    }
  }


  search(e) {
    e.stopPropagation();
    this.search_txt = e.target.value;
    this.RoomService.getInsuser(1, this.search_txt ? this.search_txt : '', '').subscribe(data => {
      this.$user = data.data.docs;
      this.cur_page = data.data.page;
      this.totalPages = data.data.totalPages;
      this.totalcount = data.data.totalDocs;
      this.pagingCounter = data.data.pagingCounter;
    });
  }

  

  editUser(e, obj) {
    this.isEdit = true;
    this.regId = obj._id;
    e.stopPropagation();
    console.log(obj)
    this.userForm.patchValue({
      department:obj.role_id,
      id: obj._id,
      name: obj.name,
      mobile_number: obj.mobile_number,
      email: obj.email,
      institution: obj.institute_code
    });
  }

  deleteUser(e, list) {
    console.log(list)
    e.stopPropagation();
    var that = this;
    Swal.fire({
      title: 'Are you sure you want to delete this User?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        that.RoomService.deleteUser(list._id).subscribe(data => {
          if (data.status.code == 0) {
            that.toastr.success(data.status.message)
            that.RoomService.getInsuser(that.cur_page, that.search_txt ? that.search_txt : '', 'INSTITUTE').subscribe(data => {
              that.$user = data.data.docs;
              that.cur_page = data.data.page;
              that.totalPages = data.data.totalPages;
              that.totalcount = data.data.totalDocs;
              that.pagingCounter = data.data.pagingCounter;
            });
            // that.userForm.reset();
          } else {
            that.toastr.error(data.status.message)
          }
        })
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.userForm.invalid) {
      this.toastr.error('Please Enter all fields');
      return false;
    }
    var that=this;

    Swal.fire({
      title: 'Are you sure you want to create this Institute User?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {

        that.loading = true;
        that.RoomService.saveInsuser(
          that.f.department.value,
          that.f.institution.value,
          that.f.name.value,
          that.f.mobile_number.value,
          that.f.email.value,
          2,
          'INSTITUTE'
        )
          .subscribe(
            data => {
              if (data.status.code == 0) {
                that.toastr.success(data.status.message);
                that.RoomService.getInsuser(that.cur_page, that.search_txt ? that.search_txt : '', 'INSTITUTE').subscribe(data => {
                  that.$user = data.data.docs;
                  that.cur_page = data.data.page;
                  that.totalPages = data.data.totalPages;
                  that.totalcount = data.data.totalDocs;
                  that.pagingCounter = data.data.pagingCounter;
                });
                that.userForm.reset();

                that.submitted = false;
                that.loading = false;
              } else {
                that.loading = false;
                that.toastr.error(data.status.message);
              }
            },
            error => {
              that.toastr.error(error)
              that.loading = false;
            });
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info')
      }
    })

}

cancel() {
 
  this.isEdit = false;
  this.ngOnInit();
}

}
