<site-header></site-header>
<div class="page-container">
  <div class="page-content-wrapper">
        <div class="page-content">
            <div class="page-bar">
                <div class="page-title-breadcrumb">
                    <div class=" pull-left">
                        <div class="page-title">Occupied Rooms Report</div>
                    </div>
                    <ol class="breadcrumb page-breadcrumb pull-right">
                        <li><i class="fa fa-home"></i>&nbsp;<a class="parent-item"
                                >Home</a>&nbsp;<i class="fa fa-angle-right"></i>
                        </li>
                        <li class="active">Occupied Rooms Report</li>
                    </ol>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                <div class="row">
                <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                    <div class="row center">
                        <button type="button"
                        class=" mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-primary text-center print"
                         printSectionId="occupied"   styleSheetFile="assets/css/style.css" [useExistingCss]="true" ngxPrint >Print </button>
                        
                        </div>
                    </div>
                        <div class="card-body clss" >
                    <div class="row " id="occupied">
                        <span class="occu" style="padding-left: 43%;
                        width: 100%;
                        font-size: 19px;
                        font-weight: 500;
                        padding-bottom: 9px;">Occupied Rooms Report</span>

                        <table class="table display product-overview mb-30" id="support_table5" >
                            <thead style="background-color: #167CE4; color: white;">
                               <tr class="mdl-data-table__cell--non-numeric">

                                <td>Room Type</td>
                                <td>Room Number</td>
                                <td>Guest Name</td>
                                <td>Phone</td>
                                <td>City</td>
                                <td>Arrival Date</td>
                                <td>Departure Date</td>
                             
                               </tr>
                            </thead>
                          
                        
                            <tbody>
                               <tr *ngFor="let chk of checkindata">

                               <td >{{chk.room_type[0] && chk.room_type[0].room_type?chk.room_type[0].room_type:''}}</td>
                                 
                               
                               <td >{{chk.room_number ?chk.room_number:''}}</td>
                               <td >{{chk.bookings[0] && chk.bookings[0].name?chk.bookings[0].name:''}}</td>
                               <td >{{chk.bookings[0] && chk.bookings[0].phone?chk.bookings[0].phone:''}}</td>
                               <td >{{chk.bookings[0] && chk.bookings[0].city?chk.bookings[0].city:''}}</td>
                               <td >{{chk.bookings[0].arrival_date | date : 'dd-MMM-yyyy' }}</td>
                               <td >{{chk.bookings[0].checkout_date | date:'dd-MMM-yyyy'}}</td>
                                 
                               </tr>

                            
                            </tbody>
                         </table>

                        
                        </div>
                        </div>

                </div>
                </div>
                </div>
                </div>
                </div>
            


        </div>
        </div>
</div>