<div class="page-header navbar navbar-fixed-top flex noPrint">

    <div class="page-header-inner ">
        <div class="page-logo">
            <a routerLink="/front-desk">
                <img src="assets/images/logo.png">
            </a>
        </div>


        <a href="javascript:;" class="menu-toggler responsive-toggler" data-toggle="collapse"
            data-target=".navbar-collapse">
            <span></span>
        </a>
    </div>
    <div class="navbar-custom">
        <div class="hor-menu hidden-sm hidden-xs">
            <ul class="nav navbar-nav">
                <li class="mega-menu-dropdown  " [ngClass]="{'active':routeParam=='/dashboard'}">
                    <a routerLink="/dashboard" class="dropdown-toggle"> <i
                            class="material-icons">dashboard</i>&nbsp;Dashboard&nbsp;
                    </a>
                </li>



                <li class="mega-menu-dropdown ">
                    <a class="dropdown-toggle"><i class="fa fa-user-circle-o"></i>
                        <span class="username username-hide-on-mobile"> Admin </span>
                        <i class="fa fa-angle-down"></i>


                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <div class="mega-menu-content">
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="mega-menu-submenu">
                                            <li>
                                                <a (click)="logout()">
                                                    <i class="icon-logout"></i> Log Out </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>

            </ul>
        </div>
    </div>
</div>