<!--  <link rel="stylesheet" href="/assets/login/css/bootstrap.min.css">
 
<ng-template #customLoadingTemplate>
</ng-template>

<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading> -->
<!-- <site-header></site-header> -->


<!-- <div class="main_container flt">
       
    <div class="login_container flt">
        <div class="login_main flt">
            <div class="login_logo flt">
                <img src="assets/images/favicon.png" class="img-responsive" alt="image">
            </div>
            <div class="login_text flt">hotel-admin</div>
         
           
            <div class="login_form flt">
                 <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" >
                <div class="form-group flt">
            
                    <input type="text" formControlName="email" placeholder="Your Email" [ngClass]="{ 'is-invalid': (f.email.touched || submitted) && f.email.errors }" > 
                </div>
                <div *ngIf="(f.email.touched || submitted) && f.email.errors" class="invalid-error">
                    <div *ngIf="f.email.errors.required" style="color: #d69d99">Email is required</div>
                </div>
                <div *ngIf="(f.email.touched || submitted) && f.email.errors" class="invalid-error">
                    <div *ngIf="f.email.errors.email" style="color: #d69d99">Invalid Email</div>
                </div>
                <div class="form-group flt">
                  
                    <input type="password" formControlName="password" placeholder="Password" [ngClass]="{ 'is-invalid': (f.password.touched || submitted) && f.password.errors }">
                    
                </div>
                <div *ngIf="(f.password.touched || submitted) && f.password.errors" class="invalid-error">
                    <div *ngIf="f.password.errors.required" style="color: #d69d99">Password is required</div>
                </div>
                 <div class="login_btn flt">
                <button type="submit" data-dismiss="modal">LOG IN</button>
            </div>
             
            </form>
            </div>
              
    
            <div class="login_forgot flt">
                Forgot your password? <a href="/login">Reset it</a>
            </div>
            
        </div>
    </div>
</div> -->



<ngx-loading [show]="loading" [template]="customLoadingTemplate"></ngx-loading>
<ng-template #customLoadingTemplate>
</ng-template>
<div class="limiter">
    <div class="container-login100 page-background">
        <div class="wrap-login100">

            <form class="login100-form validate-form">
                <span class="login100-form-logo">
                    <!-- <i class="zmdi zmdi-flower"></i> -->
                    <img alt="" style="
                       background: white;
    width: 112px;
  
    height: 122px;
                    " src="assets/img/logo1.png">
                </span>

                <span class="login100-form-title p-b-34 p-t-27">
                    Log in
                </span>
                <!-- <div class="container-login100-form-btn  p-b-34 p-t-27" *ngIf="activate==true">
                    <button class="login100-form-btn" (click)="activatenow()">
                        Activate Now
                    </button>
                </div> -->
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="wrap-input100 validate-input" data-validate="Enter username">
                        <input class="input100" type="text" name="username" formControlName="email" autocomplete="off"
                            placeholder="Enter Your Email" *ngIf="!otp">
                        <input class="input100" type="text" name="username" formControlName="email" autocomplete="off"
                            placeholder="Moble No." *ngIf="otp" readonly>
                        <span class="focus-input100" data-placeholder="&#xf207;"></span>
                        <div *ngIf="(f.email.touched || submitted) && f.email.errors" class="invalid-error">
                            <div *ngIf="f.email.errors.required" style="color: #ffffff">Mobile No. or Email is required
                            </div>
                        </div>

                    </div>
                    <div class="otp_box" *ngIf="otp">
                        <div class="otp_desc flt">
                            <input type="tel" maxlength="1" class="form-control" pattern="\d*" formControlName="otp1"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                #input1 (keyup)="(input1.value.length == 1) ? input2.focus()   : ''"
                                [ngClass]="{ 'is-invalid': (f.otp1.touched || submitted) && f.otp1.errors }" />
                            <div class="filler"></div>
                            <input type="tel" maxlength="1" class="form-control" pattern="\d*" formControlName="otp2"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                #input2 (keyup)="(input2.value.length == 1) ? input3.focus() : ''"
                                [ngClass]="{ 'is-invalid': (f.otp2.touched || submitted) && f.otp2.errors }" />
                            <div class="filler"></div>
                            <input type="tel" maxlength="1" class="form-control" pattern="\d*" formControlName="otp3"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                #input3 (keyup)="(input3.value.length == 1) ? input4.focus() : ''"
                                [ngClass]="{ 'is-invalid': (f.otp3.touched || submitted) && f.otp3.errors }" />
                            <div class="filler"></div>
                            <input type="tel" maxlength="1" class="form-control" pattern="\d*" formControlName="otp4"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                #input4 [ngClass]="{ 'is-invalid': (f.otp4.touched || submitted) && f.otp4.errors }" />

                        </div>
                        <div class="resend_link flt">
                            <a (click)="resendotp()">Resend OTP?</a>
                        </div>
                    </div>

                    <!-- <div class="wrap-input100 validate-input" data-validate="Enter password">
                        <input class="input100" formControlName="password" type="password" name="pass"
                            placeholder="Password">
                        <span class="focus-input100" data-placeholder="&#xf191;"></span>
                        <div *ngIf="(f.password.touched || submitted) && f.password.errors" class="invalid-error">
                            <div *ngIf="f.password.errors.required" style="color: #ffffff">Password is required</div>
                        </div>
                    </div> -->

                    <!-- <div class="contact100-form-checkbox">
						<input class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
						<label class="label-checkbox100" for="ckb1">
							Remember me
						</label>
					</div> -->
                    <div class="container-login100-form-btn">
                        <button class="login100-form-btn">
                            Login
                        </button>
                    </div>
                </form>
                <!-- <div class="text-center p-t-90">
						<a class="txt1" href="#">
							Forgot Password?
						</a>
					</div> -->
            </form>
        </div>
    </div>
</div>


<style>
    .is-invalid {
        border: 1px solid red;
    }

    .invalid-error {
        /* display: none; */
        width: 100%;
        margin-top: .25rem;
        font-size: 80%;
        color: red !important;
    }

    .invalid-error div {
        float: left;
        margin-bottom: 1%;
    }
</style>
<!-- End Page -->