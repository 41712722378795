import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { routing } from './app.routing';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login';
import { HomeComponent } from './home/home.component';
import { SiteHeaderComponent } from './_layouts/site-header/site-header.component';
import { SiteFooterComponent } from './_layouts/site-footer/site-footer.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ModalComponent } from './_directives';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { GroundAdminService, AlertService, AuthenticationService, SettingsService, ModalService, HomeService, RoomService} from './_services';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';;
//import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as $ from "jquery";
import { FrontDeskComponent } from './front-desk/front-desk.component';
import swal from 'sweetalert2';
import { CheckinComponent } from './checkin/checkin.component';
import { ReservationComponent } from './reservation/reservation.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { jsPDF } from "jspdf";
import htmlToPdf from 'html2pdf.js';
import { EditCheckinComponent } from './edit-checkin/edit-checkin.component';
import { ExtraServiceComponent } from './extra-service/extra-service.component';
import { EditReservationComponent } from './edit-reservation/edit-reservation.component';
import { DataTablesModule } from "angular-datatables";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import {WebcamModule} from 'ngx-webcam';
import {NgxPrintModule} from 'ngx-print';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import {FloorService} from './_services/floor.service';
import * as alertify from 'alertify.js';
import { FloorMasterComponent } from './floor-master/floor-master.component';
import { AddRoomTypeComponent } from './add-room-type/add-room-type.component';
import { AddRoomMasterComponent } from './add-room-master/add-room-master.component';
import { AddTaxMasterComponent } from './add-tax-master/add-tax-master.component';
import { AddFoodMasterComponent } from './add-food-master/add-food-master.component';
import { AddItemMasterComponent } from './add-item-master/add-item-master.component';
import { AddRateTypeComponent } from './add-rate-type/add-rate-type.component';
import { FoodServiceComponent } from './food-service/food-service.component';
import { AddLaundryItemMasterComponent } from './add-laundry-item-master/add-laundry-item-master.component';
import { LaundryServiceComponent } from './laundry-service/laundry-service.component';
import { OccupiedRoomsReportComponent } from './occupied-rooms-report/occupied-rooms-report.component';
import { ReservedRoomsReportComponent } from './reserved-rooms-report/reserved-rooms-report.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { OnlinebookComponent } from './online-book/online-book.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BookingDetailsComponent } from './booking-details/booking-details.component';
import { NgxSpinnerModule } from 'ngx-spinner';
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule, 
    routing,
    NgxPrintModule,
    WebcamModule,
    FormsModule,
   
    NgxLoadingModule,
   
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    ImageCropperModule,
    DataTablesModule,
    NgxSpinnerModule,
    NgxMaterialTimepickerModule,
   /* NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circleSwish,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff'
        }),*/
    ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        NgxPaginationModule
    
    
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    AddRoomTypeComponent,
    FloorMasterComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    AddTaxMasterComponent,
    AddFoodMasterComponent,
    AddRateTypeComponent,
    AddItemMasterComponent,
    ReservedRoomsReportComponent,
    AddRoomMasterComponent,
    SignUpComponent,
      ModalComponent,
      FrontDeskComponent,
      CheckinComponent,
      ReservationComponent,
      CheckoutComponent,
      EditCheckinComponent,
     ExtraServiceComponent,
     EditReservationComponent,
     InvoiceListComponent,
     FoodServiceComponent,
     AddLaundryItemMasterComponent,
     LaundryServiceComponent,
     OccupiedRoomsReportComponent,
     OnlinebookComponent,
     CreateUserComponent,
     DashboardComponent,
     BookingDetailsComponent
  ],
  providers: [
  AuthSuperadminGuard,
  AuthVendorGuard,
   ModalService,
   HomeService,
   RoomService,
   DatePipe,
   AlertService,
   FloorService,

   AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
