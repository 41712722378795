<site-header></site-header>
<div class="page-container"></div>
<div class="page-content-wrapper" >
   <div class="page-content" >
      <div class="page-bar">
         <div class="page-title-breadcrumb">
            <div class="card-head">
               <div class="page-title">Checkout</div>
            </div>
         </div>
      </div>
      <div class="row" id="bill">
         <div class="col-sm-12">
            <div class="card-box">
               <div class="card-body">
                  <form [formGroup]="checkoutForm" role="form" #myForm="ngForm"
                  (ngSubmit)="onSubmit(checkoutForm,myForm,checkindetails)">
                  <div class="row">
                     <div class="page_cls">Room Bill</div>
                  </div>
                  <div class="maincls">
                     <div class="row">
                        <div class="col-lg-3 p-t-10 ml-2">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 value="{{latest_date}}" name="billdate" readonly="true" />
                              <label class="mdl-textfield__label label_cls">Bill Date</label>
                           </div>
                        </div>
                        <div class="col-lg-3 p-t-10 ml-5">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 autocomplete="off" readonly="true" name="billno"
                                 formControlName="billno"
                                 [ngClass]="{ 'is-invalid': (f.billno.touched || submitted) && f.billno.errors }" />
                              <label class="mdl-textfield__label label_cls">Bill No</label>
                           </div>
                        </div>
                        <div class="col-lg-4 p-t-10 ml-5">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 autocomplete="off" readonly="true" name="name" formControlName="name"
                                 [ngClass]="{ 'is-invalid': (f.name.touched || submitted) && f.name.errors }" />
                              <label class="mdl-textfield__label label_cls">Guest Name</label>
                           </div>
                        </div>
                     </div>
                     <div>
                        <hr>
                     </div>
                  </div>
                  <div class="row">
                     <div class="page_cls">Rooms Allocated for Mobile No :
                        {{checkindetails && checkindetails.phone}} & Departure Date {{ checkoutdate | date : 'dd/MM/yyyy' }} 
                     </div>
                  </div>
                  <div class="card-body tbln">
                     <!-- <table class="mdl-data-table mdl-js-data-table tdalign"> -->
                     <table class="table display product-overview mb-30" id="support_table5" datatable
                     [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                     <thead style="background-color: #167CE4; color: white;">
                        <tr>
                           <th>Select</th>
                           <th>Room No</th>
                           <th class="mdl-data-table__cell--non-numeric">Room Type</th>
                           <th>Check In No</th>
                           <th>Arival Date</th>
                           <th>Arival Time</th>
                           <th>Departure Date</th>
                           <th>Departure Time</th>
                        </tr>
                     </thead>
                     <tbody *ngFor="let chk of $checkindetails; let i=index">
                        <tr class="mdl-data-table__cell--non-numeric">
                           <ng-container *ngFor="let rmcat of chk.rooms_category">
                              <ng-container *ngFor="let rmno of chk.rooms">
                                 <!--      <ng-container *ngFor="let ex of chk.extraservice">
                                    <ng-container *ngFor="let item of ex.items">   -->
                                 <td><input type="checkbox" name="" class="case{{i}} checkoutcheck"
                                    (change)="selectChange($event,chk,i,rmcat,rmno)"></td>
                                 <td>{{rmno.room_number}}</td>
                                 <td>{{rmcat.room_type}}</td>
                                 <td>{{chk.checkin_no}}</td>
                                 <td>{{chk.booking_date | date : 'dd/MM/yyyy'}}</td>
                                 <td>{{chk.checkin_time }}</td>
                                 <td>{{chk.checkout_date | date : 'dd/MM/yyyy'}}</td>
                                 <td>{{chk.checkout_time }}</td>
                              </ng-container>
                           </ng-container>
                           <!--  </ng-container></ng-container> -->
                        </tr>
                     </tbody>
                     </table>
                  </div>
                  <div class="row" *ngIf="selectTrue">
                     <div class="col-md-6 col-sm-6">
                        <div class="card  card-box">
                           <a (click)="arrow($event)">
                              <div class="card-head">
                                 <header>ROOM CHARGE</header>
                                 <div class="tools">
                           <a class="btn-color fa fa-chevron-down"
                              (click)="arrow1($event)"></a>
                           </div>
                           </div>
                           </a>
                           <div class="card-body" *ngIf="room_charge">
                              <div class="table-wrap">
                                 <div class="table-responsive tblHeightSet small-slimscroll-style">
                                    <table class="table display product-overview mb-30"
                                       id="support_table5">
                                       <thead>
                                          <tr>
                                             <th>Room Type</th>
                                             <th>Charge</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr *ngFor="let val of checkinData">
                                             <td>{{val.roomtype}}</td>
                                             <td>{{val.roomprice}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6 col-sm-6">
                        <div class="card  card-box">
                           <a (click)="extracharge($event)">
                              <div class="card-head">
                                 <header>EXTRA PERSON / BED CHARGES</header>
                                 <div class="tools">
                           <a class="btn-color fa fa-chevron-down"
                              (click)="extracharge1($event)"></a>
                           </div>
                           </div>
                           </a>
                           <div class="card-body" *ngIf="extra_charge">
                              <div class="table-wrap">
                                 <div class="table-responsive tblHeightSet small-slimscroll-style">
                                    <!-- <ng-container *ngFor="let $ext of $checkindetails">
                                       <span class="no_data_display" *ngIf="!$ext.extra_service && !$ext.items">No extra person / bed charges</span>
                                       </ng-container> -->
                                    <span class="no_data_display"
                                       *ngIf="!itname && !itprice && !itqry && !itdays">No extra person
                                    / bed charges</span>
                                    <table class="table display product-overview mb-30"
                                       id="support_table5"
                                       *ngIf="itname && itprice && itqry && itdays">
                                       <thead>
                                          <tr>
                                             <th>Item Name</th>
                                             <th>Price</th>
                                             <th>Quantity</th>
                                             <th>No of Days</th>
                                          </tr>
                                       </thead>
                                       <tbody *ngFor="let $ext of dispextradata">
                                          <tr *ngFor="let $ex of $ext">
                                             <td>{{$ex.item_name && $ex.item_name.name}}</td>
                                             <td>{{$ex.item_name && $ex.item_name.price}}</td>
                                             <td>{{$ex.quantity}}</td>
                                             <td>{{$ex.totalday}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="row" *ngIf="selectTrue">
                     <div class="col-sm-6">
                        <div class="card  card-box">
                           <a (click)="laundryCharge($event)">
                              <div class="card-head">
                                 <header>LAUNDRY CHARGES</header>
                                 <div class="tools">
                           <a class="btn-color fa fa-chevron-down" (click)="laundryCharge1($event)"></a>
                           </div>
                           </div></a>
                           <div class="card-body " *ngIf="laundry_charge">
                              <div class="table-wrap">
                                 <div class="table-responsive tblHeightSet small-slimscroll-style">
                                    <span class="no_data_display"
                                       *ngIf="!laundry_name&& !laundryquantity && !laundryprice">No laundry charges</span>
                                    <table *ngIf="laundry_name&& laundryquantity && laundryprice" class="table display product-overview mb-30" id="support_table5">
                                       <thead>
                                          <tr>
                                             <th>Item Name</th>
                                             <th>Price</th>
                                             <th>Quantity</th>
                                             <th>Total Amount</th>
                                          </tr>
                                       </thead>
                                       <tbody *ngFor="let $laundry of displaundrydata">
                                          <tr *ngFor="let $ld of $laundry">
                                             <td>{{$ld.laundry_name && $ld.laundry_name.name}}</td>
                                             <td>{{$ld.laundry_name && $ld.laundry_name.price}}</td>
                                             <td>{{$ld.quantity}}</td>
                                             <td>{{$ld.amount}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="card  card-box">
                           <a (click)="restcharge($event)">
                              <div class="card-head">
                                 <header>RESTAURANT CHARGES</header>
                                 <div class="tools">
                           <a class="btn-color fa fa-chevron-down" (click)="restcharge1($event)"></a>
                           </div>
                           </div></a>
                           <div class="card-body" *ngIf="restaurant_charge">
                              <div class="table-wrap">
                                 <div class="table-responsive tblHeightSet small-slimscroll-style">
                                    <span class="no_data_display"
                                       *ngIf="!foodname&& !foodqty && !foodprice">No food charges</span>
                                    <table  *ngIf="foodname&& foodqty && foodprice" class="table display product-overview mb-30" id="support_table5">
                                       <thead>
                                          <tr>
                                             <th>Food Name</th>
                                             <th>Price</th>
                                             <th>Quantity</th>
                                             <th>Total Amount</th>
                                          </tr>
                                       </thead>
                                       <tbody *ngFor="let $food of dispfooddata">
                                          <tr *ngFor="let $fd of $food">
                                             <td>{{$fd.food_name && $fd.food_name.name}}</td>
                                             <td>{{$fd.food_name && $fd.food_name.price}}</td>
                                             <td>{{$fd.quantity}}</td>
                                             <td>{{$fd.amount}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="card-body tbln t" *ngIf="selectTrue">
                     <table class="mdl-data-table mdl-js-data-table tdalign tbq">
                        <thead>
                           <tr>
                              <th>Room No</th>
                              <th>No of Days</th>
                              <th>Tariff</th>
                              <th>Room Discount</th>
                              <th>Total Tariff</th>
                              <th>CGST(%)</th>
                              <th>CGST.Amt</th>
                              <th>SGST(%)</th>
                              <th>SGST.Amt</th>
                              <th>TotalAmount</th>
                           </tr>
                        </thead>
                        <tbody class="appenddata">
                           <tr class="mdl-data-table__cell--non-numeric"
                              *ngFor="let val of checkinData; let i= index" id="rowdata{{i}}">
                              <td>{{val.roomno}}</td>
                              <td>{{val.days}}</td>
                              <td>{{val.price}}</td>
                              <td>{{val.discount_amount}}</td>
                              <td>{{val.totalprice}}</td>
                              <td>{{val.cgstp}}</td>
                              <td>{{val.cgst | number: '1.0-0'}}</td>
                              <td>{{val.sgstp}}</td>
                              <td>{{val.sgst | number: '1.0-0'}}</td>
                              <td>{{val.total}}</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div class="maincls" *ngIf="selectTrue">
                     <div class="row">
                        <div class="col-lg-4 p-t-10 ml-5">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 name="roomtariff" formControlName="roomtariff"
                                 [ngClass]="{ 'is-invalid': (f.roomtariff.touched || submitted) && f.roomtariff.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Room Tariff Charge</label>
                           </div>
                        </div>
                        <div class="col-lg-4 p-t-10 ml-5">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 name="roomtariff" formControlName="discountamt"
                                 [ngClass]="{ 'is-invalid': (f.discountamt.touched || submitted) && f.discountamt.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Room Discount Amount</label>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-2 p-t-10 ml-2">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 name="totalroom" formControlName="totalroom"
                                 [ngClass]="{ 'is-invalid': (f.totalroom.touched || submitted) && f.totalroom.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Total Room Charge</label>
                           </div>
                        </div>
                        <div class="col-lg-2 p-t-10 ml-4">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo" name="cgst"
                                 formControlName="cgst"
                                 [ngClass]="{ 'is-invalid': (f.cgst.touched || submitted) && f.cgst.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Total CGST</label>
                           </div>
                        </div>
                        <div class="col-lg-2 p-t-10 ml-4">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo" name="sgst"
                                 formControlName="sgst"
                                 [ngClass]="{ 'is-invalid': (f.sgst.touched || submitted) && f.sgst.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Total SGST</label>
                           </div>
                        </div>
                        <div class="col-lg-2 p-t-10 ml-4">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 name="extracharge" formControlName="extracharge"
                                 [ngClass]="{ 'is-invalid': (f.extracharge.touched || submitted) && f.extracharge.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Total Extra Person / Bed
                              Charge</label>
                           </div>
                        </div>
                        <div class="col-lg-2 p-t-10 ml-4">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 name="laundry" formControlName="laundry"
                                 [ngClass]="{ 'is-invalid': (f.laundry.touched || submitted) && f.laundry.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Laundry Services
                              Charge</label>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-2 p-t-10 ml-2">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 name="restaurant" formControlName="restaurant"
                                 [ngClass]="{ 'is-invalid': (f.restaurant.touched || submitted) && f.restaurant.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Restaurant Charge</label>
                           </div>
                        </div>
                        <div class="col-lg-2 p-t-10 ml-4">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo" name="gross"
                                 formControlName="gross"
                                 [ngClass]="{ 'is-invalid': (f.gross.touched || submitted) && f.gross.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Gross Amount</label>
                           </div>
                        </div>
                        <div class="col-lg-2 p-t-10 ml-4">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo" name="disc" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              formControlName="disc"
                              [ngClass]="{ 'is-invalid': (f.disc.touched || submitted) && f.disc.errors }"
                              (change)="discount($event,i)" />
                              <label class="mdl-textfield__label label_cls">Disc(%)</label>
                           </div>
                        </div>
                        <div class="col-lg-2 p-t-10 ml-4">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 name="discamount" formControlName="discamount"
                                 [ngClass]="{ 'is-invalid': (f.discamount.touched || submitted) && f.discamount.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Disc Amount</label>
                           </div>
                        </div>
                        <div class="col-lg-2 p-t-10 ml-4">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 name="netamount" formControlName="netamount"
                                 [ngClass]="{ 'is-invalid': (f.netamount.touched || submitted) && f.netamount.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Net Amount</label>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-3 p-t-10 ml-2">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 name="paidamount" formControlName="paidamount"
                                 [ngClass]="{ 'is-invalid': (f.paidamount.touched || submitted) && f.paidamount.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Total Amount Paid</label>
                           </div>
                        </div>
                        <div class="col-lg-3 p-t-10 ml-5">
                           <div
                              class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                              <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                 name="balance" formControlName="balance"
                                 [ngClass]="{ 'is-invalid': (f.balance.touched || submitted) && f.balance.errors }"
                                 readonly="true" />
                              <label class="mdl-textfield__label label_cls">Balance</label>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-lg-12 p-t-20 text-center">
                           <button type="submit"
                              class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-primary text-center"
                              *ngIf="save">SUBMIT</button>
                           <button type="button"
                           class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-primary text-center"
                           (click)="openPDF(checkindetails)" *ngIf="printdata">Print Invoice</button>
                           <button type="submit"
                              class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
                              (click)="cancel()">CANCEL</button>
                        </div>
                     </div>
                  </div>
                  </form>
               </div>
               <div class="row" *ngIf="$checkindetails && $checkindetails.length">
                  <div class="col-lg-12 p-t-20 text-center">
                     <span class="no_data_display" *ngIf="!selectTrue">No room selected!</span>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-12 p-t-20 text-center">
                     <span class="no_data_display" *ngIf="$checkindetails && !$checkindetails.length">Rooms are
                     empty</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <button type="button" 
      class=" mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-primary text-center print"
      printSectionId="invoice"    ngxPrint *ngIf="printdata" styleSheetFile="assets/css/style.css" >Print Invoice</button>
      <div class="row" id="invoice" style=" 
  
      font-family: Poppins,sans-serif;
      font-size: 13px;
      width: 100%;
      height: 100%;
      font-weight: 400;
      line-height: 1.3;
      color: rgba(0,0,0,.87);">
         <div class="col-sm-12">
            <div class="card-box">
               <div class="card-body">

              
                     <div style="float: left;width: 100%;position: relative;" >
                        <div style="position: absolute;">
                                <img src="assets/images/logo1.png" style="width: 42px;height: 52px;">
                     </div>
                           <div style="text-align: center;float: left;width: 100%;font-weight: 600;font-size: 13px;">
                                    <span >
                                       SHIVAPRASAD GRAND
                                    </span><br>

                                    <span >
                                    NH 66, Main Road Kundapura, Udupi - 576201
                                    </span><br>
                                    <span >
                                       TAX INVOICE
                                       </span>
                                 </div>
                                 <div style="text-align: right;width: 100%;font-size: 13px;font-weight: bold;">
                                    <span>
                                       GSTIN:29AADFS6711P1ZM<br>www.shivaprasadgrand.com<br>08254230377
                                    </span>
                                 </div>

                     </div>
                     <div class="row" style="display: flex;font-size: 13px;width:100%;float:left; ">
                        <div class="col-sm-6 ">
                           <span style="margin: 5px;"><b>Guest Name :</b>
                           {{checkindetails && checkindetails.name | titlecase}} </span>
                           <br> <span style="margin: 5px;"><b>Address :</b> {{checkindetails && checkindetails.address | titlecase}}</span>
                           <br *ngIf="checkindetails && checkindetails.gstid"> <span style="margin: 5px;" *ngIf="checkindetails && checkindetails.gstid"><b>Customer GSTIN :</b> {{checkindetails && checkindetails.gstid |uppercase }}</span>
                           <br> <span style="margin: 5px;"> <b>Arrival Date Time :
                           </b>{{checkindetails && checkindetails.booking_date | date:'dd-MM-yyyy'}}
                           {{checkindetails && checkindetails.checkin_time }} </span>
                           <br> 
                           <span style="margin: 5px;">
                              <b>Departure Date Time :
                              </b>{{checkindetails && checkindetails.checkout_date | date:'dd-MM-yyyy'}}
                              {{checkindetails && checkindetails.checkout_time }}
                              <!-- <span id="invoice_dates1">{{invoice_dates}}</span> -->
                           </span>
                        </div>
                        <div class="filler" style="flex-grow: 1;"></div>
                        <div class="col-sm-6 ">
                           <span><b></b></span>
                           <br> <span style="    margin-left: 65px;"><b>Invoice No : </b><span id="invoice_no">{{invoice_no}}</span> </span>
                           <br> <span style="    margin-left: 65px;"><b>Invoice Date :</b><span
                              id="invoice_dates">{{invoice_dates}}</span></span>
                           <!-- <br> <span style="margin: 5px;"><b>Room No :</b>
                              <ng-container *ngFor="let rmz of checkindetails && checkindetails.rooms">
                                  {{rmz.room_number ? rmz.room_number : ''}}
                              </ng-container>
                              </span> -->
                        </div>
                     </div>

                     <div style="padding: 6px;width: 100%;float: left;text-align: center;font-size: 13px; font-weight: bold;background-color: #f1efef;margin-top: 15px;    border: 1px solid #f1efef;">
                       <span>Room charges</span> 
                     </div>
                     <div style="width:100%;float:left">
                        <table style="width: 100%;
                        border-collapse: collapse;">
                           <thead>
                              <tr>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">Room No</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px; ">No of Days</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">Tariff</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px; " *ngIf="totaldiscountamts">Room Discount</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">Total Charges</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">CGST(%)</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">CGST.Amt</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">SGST(%)</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">SGST.Amt</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">Total Amount</th>
                              </tr>
                           </thead>
                           <tbody  style="border-top: 1px solid #000;">
                              <tr *ngFor="let val of checkinData">
                                 <td style="width: 40px;text-align: left;padding-right: 5px;">{{val.roomno}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px;">{{val.days}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; ">{{val.price}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; " *ngIf="totaldiscountamts">{{val.discount_amount}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; ">{{val.totalprice | number: '1.0-0'}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; ">{{val.cgstp}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; ">{{val.cgst | number: '1.0-0'}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; ">{{val.sgstp}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; ">{{val.sgst | number: '1.0-0'}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; ">{{val.total}}</td>
                              </tr>
                              <tr  style=" border-bottom: 1px solid #000;border-top: 1px dotted #000;color: #0e0e0e;font-weight: 600;">
                                 <td style="width: 40px;text-align: left;padding-right: 5px; " colspan="2">Total Room Charge</td>
                                 
                                 <td style="width: 40px;text-align: left;padding-right: 5px; ">{{charge | number: '1.0-0'}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; " *ngIf="totaldiscountamts">{{totaldiscountamts}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; ">{{totalcharge | number: '1.0-0'}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; "></td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; ">{{cgamtf}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; "></td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; ">{{sgamtf}}</td>
                                 <td style="width: 40px;text-align: left;padding-right: 5px; ">{{grandtotalfinal}}</td>
                              </tr>
                           </tbody>
                        </table>

                     </div>

                     <div *ngIf="extrarotachargef" style="padding: 6px;width: 100%;float: left;text-align: center;font-size: 13px; font-weight: bold;background-color: #f1efef;margin-top: 36px;border: 1px solid #f1efef;">
                        <span>Extra Item / Services
                           Charges </span> 
                      </div>
                      <div *ngIf="extrarotachargef" style="width:100%;float:left">
                        <table style="width: 100%;
                        border-collapse: collapse;">
                           <thead>
                              <tr>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">Room No</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">Item</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">Qty</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">Rate</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">Total Charges</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">GST(%)</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">GST Amt.</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">SGST(%)</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">SGSTAmt.</th>
                                 <th style="width: 40px;text-align: left;padding-right: 5px;">Total</th>
                              </tr>
                              </thead>
                              <tbody style="border-top: 1px solid #000;">
                                 <tr *ngFor="let exdata of extrachargeData">
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.roomno}}</td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.itemname}}</td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.qty}}</td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.rate}}</td>
                                    <!--   <td>{{totalchargeextra ? totalchargeextra : 0}}</td> -->
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{(exdata.qty * exdata.rate * exdata.days) | number: '1.0-0' }}</td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.cgstp}}</td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.cgst | number: '1.0-0'}}</td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.sgstp}}</td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.sgst | number: '1.0-0'}}</td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.totalextra}}</td>
                                 </tr>
                                 <tr 
                                    style=" border-bottom: 1px solid #000;border-top: 1px dotted;color: #0e0e0e;font-weight: 600;">
                                    <td style="width: 40px;text-align: left;padding-right: 5px;" colspan="3">Total Extra Item / Services Charge</td>
                                   
                                   
                                    <td style="width: 40px;text-align: left;padding-right: 5px;"></td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{extrarotachargef? extrarotachargef : 0}}</td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;"></td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{extracgstamt ? extracgstamt : 0}}</td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;"></td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{extrasgstamt ? extrasgstamt : 0}}</td>
                                    <td style="width: 40px;text-align: left;padding-right: 5px;">{{extratotalf ? extratotalf : 0}}</td>
                                 </tr>
                              </tbody>
                              </table>
                           </div>
                    
                           <div *ngIf="extrarotafoodchargef" style="padding: 6px;width: 100%;float: left;text-align: center;font-size: 13px; font-weight: bold;background-color: #f1efef;margin-top: 36px;border: 1px solid #f1efef;">
                              <span>Restaurant Charges</span> 
                            </div>
                            <div *ngIf="extrarotafoodchargef" style="width:100%;float:left">
                              <table style="width: 100%;
                              border-collapse: collapse;">
                                 <thead>
                                    <tr>
                                       <th style="width: 40px;text-align: left;padding-right: 5px;">Room No</th>
                                       <th style="width: 40px;text-align: left;padding-right: 5px;">Food name</th>
                                       <th style="width: 40px;text-align: left;padding-right: 5px;">Qty</th>
                                       <th style="width: 40px;text-align: left;padding-right: 5px;">Rate</th>
                                       <th style="width: 40px;text-align: left;padding-right: 5px;">Total Charges</th>
                                    </tr>
                                    </thead>
                                    <tbody style="border-top: 1px solid #000;">
                                       <tr *ngFor="let exfdata of extrafoodchargeData">
                                          <td style="width: 40px;text-align: left;padding-right: 5px;">{{exfdata.roomno}}</td>
                                          <td style="width: 40px;text-align: left;padding-right: 5px;">{{exfdata.foodname}}</td>
                                          <td style="width: 40px;text-align: left;padding-right: 5px;">{{exfdata.qty}}</td>
                                          <td style="width: 40px;text-align: left;padding-right: 5px;">{{exfdata.rate}}</td>
                                          <td style="width: 40px;text-align: left;padding-right: 5px;">{{exfdata.totalfoodextra}}</td>
                                         
                                       </tr>
                                       <tr 
                                          style=" border-bottom: 1px solid #000;border-top: 1px dotted;color: #0e0e0e;font-weight: 600;">
                                          <td style="width: 40px;text-align: left;padding-right: 5px;" colspan="2">Total Restaurant Charges</td>
                                        
                                          <td style="width: 40px;text-align: left;padding-right: 5px;"></td>
                                          <td style="width: 40px;text-align: left;padding-right: 5px;"></td>
                                          <td style="width: 40px;text-align: left;padding-right: 5px;">{{extrafoodtotalf ? extrafoodtotalf : 0}}</td>
                                       </tr>
                                    </tbody>
                                    </table>
                                  </div>


                                  <div *ngIf="extrarotalaundrychargef" style="padding: 6px;width: 100%;float: left;text-align: center;font-size: 13px; font-weight: bold;background-color: #f1efef;margin-top: 36px;border: 1px solid #f1efef;">
                                    <span>Laundry Charges</span> 
                                  </div>
                                  <div *ngIf="extrarotalaundrychargef" style="width:100%;float:left">
                                    <table style="width: 100%;
                                    border-collapse: collapse;">
                                       <thead>
                                          <tr>
                                             <th  style="width: 40px;text-align: left;padding-right: 5px;">Room No</th>
                                             <th style="width: 40px;text-align: left;padding-right: 5px;">Item name</th>
                                             <th style="width: 40px;text-align: left;padding-right: 5px;">Qty</th>
                                             <th style="width: 40px;text-align: left;padding-right: 5px;">Rate</th>
                                             <th style="width: 40px;text-align: left;padding-right: 5px;">Total Charge</th>
                                             <!-- <th style="width: 40px;text-align: left;padding-right: 5px;">GST(%)</th>
                                                <th style="width: 40px;text-align: left;padding-right: 5px;">GST Amt.</th>
                                                <th style="width: 40px;text-align: left;padding-right: 5px;">SGST(%)</th>
                                                <th style="width: 40px;text-align: left;padding-right: 5px;">SGSTAmt.</th>
                                                <th style="width: 40px;text-align: left;padding-right: 5px;">Total</th> -->
                                          </tr>
                                             </thead>
                                             <tbody  style="border-top: 1px solid #000;">
                                                <tr *ngFor="let exldata of extralaundrychargeData">
                                                   <td style="width: 40px;text-align: left;padding-right: 5px;">{{exldata.roomno}}</td>
                                                   <td style="width: 40px;text-align: left;padding-right: 5px;">{{exldata.laundry_name}}</td>
                                                   <td style="width: 40px;text-align: left;padding-right: 5px;">{{exldata.qty}}</td>
                                                   <td style="width: 40px;text-align: left;padding-right: 5px;">{{exldata.rate}}</td>
                                                   <td style="width: 40px;text-align: left;padding-right: 5px;">{{exldata.totallaundryextra}}</td>
                                                   <!-- <td>{{totalchargeextra ? totalchargeextra : 0}}</td> -->
                                                   <!-- <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.qty * exdata.rate * exdata.days}}</td> -->
                                                   <!-- <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.cgstp}}</td>
                                                      <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.cgst}}</td>
                                                      <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.sgstp}}</td>
                                                      <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.sgst}}</td>
                                                      <td style="width: 40px;text-align: left;padding-right: 5px;">{{exdata.totalextra}}</td> -->
                                                </tr>
                                                <tr 
                                                   style=" border-bottom: 1px solid #000;border-top: 1px dotted;color: #0e0e0e;font-weight: 600;">
                                                   <td style="width: 40px;text-align: left;padding-right: 5px;">Total Laundry Charges</td>
                                                   <td style="width: 40px;text-align: left;padding-right: 5px;"></td>
                                                   <td style="width: 40px;text-align: left;padding-right: 5px;"></td>
                                                   <td style="width: 40px;text-align: left;padding-right: 5px;"></td>
                                                   <td style="width: 40px;text-align: left;padding-right: 5px;">{{extralaundrytotalf ? extralaundrytotalf : 0}}</td>
                                                </tr>
                                             </tbody>
                                             </table>
                                             </div>




                               <!-- <div style="    width: 100%;
                               margin-top: 65px;
                               float: left;
                               border-top: 1px solid black;
                               border-bottom: 1px solid black;">
                                              <div style="width:50%;float:left;    padding-top: 75px;">
                                                <ul style="list-style-type: none;">
                         
                                                   <li style="float: left;
                                                   width: 50%;
                                                   text-align: right;"> <span style="font-size: 13px;
                                                      font-weight: 600;">CGST -</span></li>
                                                        <li style="float: left;
                                                        width: 50%;
                                                        text-align: right;padding-right: 17px;"> <span style="font-size: 13px;">  {{cgamtf  + extracgstamt}}</span></li>
                                                            <li style="float: left;
                                                            width: 50%;
                                                            text-align: right;"> <span style="font-size: 13px;
                                                               font-weight: 600;">SGST -</span></li>
                                                          <li style="float: left;
                                                          width: 50%;
                                                          text-align: right;     padding-right: 17px;"> <span style="font-size: 13px;"> {{sgamtf + extrasgstamt}} </span></li>
                                                        </ul>
                                            </div>
                                              <div style="width:50%;float:left;    border-left: 1px solid black;">
                                                <ul style="list-style-type: none;">
                         
                                                   <li style="float: left;
                                                   width: 50%;
                                                   text-align: right;"> <span style="font-size: 13px;
                                                      font-weight: 600;">Gross Amount</span></li>
                                                        <li style="float: left;
                                                        width: 50%;
                                                        text-align: right;"> <span style="font-size: 13px;"> {{( grandtotalfinal + extratotalf+extrafoodtotalf+extralaundrytotalf)}} </span></li>

<li style="float: left;
width: 50%;
text-align: right;"> <span style="font-size: 13px;
   font-weight: 600;">Less: Discount</span></li>
     <li style="float: left;
     width: 50%;
     text-align: right;"> <span style="font-size: 13px;">{{discountamount ? discountamount : 0}} </span></li>
       <li style="float: left;
       width: 50%;
       text-align: right;"> <span style="font-size: 13px;
          font-weight: 600;">Net Amount</span></li>
            <li style="float: left;
            width: 50%;
            text-align: right;"> <span style="font-size: 13px;"> {{netamountss}} </span></li>
              <li style="float: left;
              width: 50%;
              text-align: right;"> <span style="font-size: 13px;
                 font-weight: 600;">Less: Advance Paid</span></li>
                   <li style="float: left;
                   width: 50%;
                   text-align: right;"> <span style="font-size: 13px;"> {{paidadvance ? paidadvance  : 0}} </span></li>



<li style="float: left;
width: 50%;
text-align: right;    border-top: double;"> <span style="font-size: 13px;
   font-weight: 600;">Balance</span></li>
     <li style="float: left;
     width: 50%;
     text-align: right;     border-top: double;"> <span style="font-size: 13px;"> {{finalbalances}} </span></li>
                                                </ul>

                                             </div>
                                 </div> -->
           <div style="width: 100%;float: left;padding-top: 57px;">
            <table  style="border-bottom: 2px solid;font-size: 13px;
            border-top: 2px solid ;width: 100%;
                        border-collapse: collapse;">
            <tr>
               <td colspan="4"  style=" display: table-cell;
                  vertical-align: inherit;padding-top: 17px; "></td>
               <td  style="border-bottom: hidden;border-left: 2px solid black !important ;display: table-cell;
                  vertical-align: inherit;    padding-top: 17px;"><span 
                  style=" font-weight: 600;padding-left: 100px;">Gross Amount</span> <span
                
                  style="float: right;font: caption;font-weight: 600;">{{( grandtotalfinal + extratotalf+extrafoodtotalf+extralaundrytotalf)}}</span>
               </td>
            </tr>
            <tr>
               <td colspan="4"  ></td>
               <td  style="border-bottom: hidden; border-left: 2px solid black !important"> <span 
                  style=" font-weight: 600;padding-left: 100px;">Less: Discount</span>
                  <span 
                     style="float: right;font: caption;font-weight: 600;">
                  {{discountamount ? discountamount : 0}}</span>
               </td>
            </tr>
            <tr>
               <td colspan="4"  ></td>
               <td  style="border-bottom: hidden; border-left: 2px solid black !important"><span 
                  style=" font-weight: 600;padding-left: 100px;">Net Amount</span> <span
                    style="float: right;font: caption;font-weight: 600;" >
                  {{netamountss}}</span>
               </td>
            </tr>
            <tr>
               <td colspan="4"  ></td>
               <td  style="border-bottom: hidden; border-left: 2px solid black !important"><span 
                  style=" font-weight: 600;padding-left: 100px;">Less: Advance Paid
                  </span> <span 
                     style="float: right;font: caption;font-weight: 600;">{{paidadvance ? paidadvance  : 0}}</span>
               </td>
            </tr>
            <tr>
               <td colspan="4"  style="border-right:2px solid black" ><span
                  style="font-weight: 600;padding-left: 250px;">CGST -
                  {{cgamtf  + extracgstamt}}</span>
               </td>
            </tr>
            <tr>
               <td colspan="4" style="border-right:2px solid black" ><span 
                  style="font-weight: 600;padding-left: 250px;">SGST -
                  {{sgamtf + extrasgstamt}}</span>
               </td>
               <td style="border-top:double;"><span 
                  style=" font-weight: 600;padding-left: 100px;">Balance</span> <span
                  style="float: right;font: caption;font-weight: 600;">
                  {{finalbalances}}</span>
               </td>
            </tr>
         </table></div>
           
         <div style="width: 100%;float: left;padding-top: 50px;">
            <span class="btm_cls" style="font-size: 13px;"><span class="cust"><b>Customer</b></span> &nbsp;&nbsp;&nbsp;&nbsp; We thank you for your
            stay and hope to see you again in the future. &nbsp;<span class="recp"><b>Receptionist /
            Accountant</b></span></span></div>
                              </div>


                              
      </div>
          
      </div>
   </div>
</div>
<site-footer></site-footer>