import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertService, ModalService, RoomService } from '../_services';
import { DatePipe, formatDate, getLocaleDateTimeFormat } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import htmlToPdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { data } from 'jquery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IfStmt } from '@angular/compiler';

declare var html2pdf: any;
declare var alertify: any;
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-online-book',
  templateUrl: './online-book.component.html',
  styleUrls: ['./online-book.component.css']
})
export class OnlinebookComponent implements OnInit {
  @ViewChild('invoice') invoice: ElementRef;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  onlinebook$: any;
  viewDetails = false;
  invoice_details: any;
  details$: any;
  currentdate: any;
  latest_date: any;
  print: any;
  invoicebutton = false;
  media_domain = `${environment.media_domain}`;
  tutorials: any;
  currentTutorial = null;
  currentIndex = -1;
  title = '';
  cur_page = 1;
  perPage = 10;
  pageSize = 10;
  totalPages = 10;
  totalcount: any;
  pagingCounter: any;
  status = 0;
  search_txt: any;
  editUser: any;
  approveCount = 0;
  pendingCount = 0;
  rejectCount = 0;
  pan_img: any;
  rejectForm: FormGroup;
  submitted: boolean;
  room: any;
  type: any;

  constructor(
    private roomSer: RoomService,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.viewDetails = false;
    this.reFreshonce();
    this.rejectForm = this.formBuilder.group({
      reason: ['', Validators.required]
    })
  }
  get f() { return this.rejectForm.controls; }

  reFreshonce() {
    this.roomSer.getOnline(this.status, this.cur_page, this.search_txt).subscribe((data) => {
      this.onlinebook$ = data.data.docs;
      this.cur_page = data.data.page;
      this.totalPages = data.data.totalPages;
      this.totalcount = data.data.totalDocs;
      this.pagingCounter = data.data.pagingCounter;
    });
    this.roomSer.getBookcount().subscribe(data => {

      data.data.forEach(element => {
        console.log(element._id)
        if (element._id == 0) {
          this.pendingCount = element.count;
        }
        if (element._id == 1) {
          this.approveCount = element.count;
        }
        if (element._id == 2) {
          this.rejectCount = element.count;
        }
      });

    })
  }

  changePage(page) {
    this.cur_page = page;
    this.roomSer.getOnline(this.status, this.cur_page, this.search_txt).subscribe((data) => {
      this.onlinebook$ = data.data.docs;
      this.cur_page = data.data.page;
      this.totalPages = data.data.totalPages;
      this.totalcount = data.data.totalDocs;
      this.pagingCounter = data.data.pagingCounter;
    });
  }

  search(e) {
    this.search_txt = e.target.value;
    this.roomSer.getOnline(this.status, 1, this.search_txt).subscribe((data) => {
      this.onlinebook$ = data.data.docs;
      this.cur_page = data.data.page;
      this.totalPages = data.data.totalPages;
      this.totalcount = data.data.totalDocs;
      this.pagingCounter = data.data.pagingCounter;
    });
  }

  view(user) {
    this.viewDetails = true;
    this.editUser = user;
  }

  approve(e) {
    this.status = 1;
    this.cur_page = 1;
    // this.search_txt = '';
    this.roomSer.getOnline(this.status, this.cur_page, this.search_txt ? this.search_txt : '').subscribe((data) => {
      this.onlinebook$ = data.data.docs;
      this.cur_page = data.data.page;
      this.totalPages = data.data.totalPages;
      this.totalcount = data.data.totalDocs;
      this.pagingCounter = data.data.pagingCounter;
    });
  }

  pending(e) {
    this.status = 0;
    this.cur_page = 1;
    // this.search_txt = '';
    this.roomSer.getOnline(this.status, this.cur_page, this.search_txt ? this.search_txt : '').subscribe((data) => {
      this.onlinebook$ = data.data.docs;
      this.cur_page = data.data.page;
      this.totalPages = data.data.totalPages;
      this.totalcount = data.data.totalDocs;
      this.pagingCounter = data.data.pagingCounter;
    });
  }

  reject(e) {
    this.status = 2;
    this.cur_page = 1;
    // this.search_txt = '';
    this.roomSer.getOnline(this.status, this.cur_page, this.search_txt ? this.search_txt : '').subscribe((data) => {
      this.onlinebook$ = data.data.docs;
      this.cur_page = data.data.page;
      this.totalPages = data.data.totalPages;
      this.totalcount = data.data.totalDocs;
      this.pagingCounter = data.data.pagingCounter;
    });
  }

  back(e) {
    this.search_txt = '';
    this.reFreshonce();
    this.viewDetails = false;
  }

  delete(room) {
    var that = this;
    alertify.confirm('Delete', 'Are you sure want to delete this record', function () {
      that.roomSer && that.roomSer.deleteRoom(room._id).subscribe(data => {
        if (data.status.code == 0) {
          that.toastr.success(data.status.message);
          that.roomSer.getBookcount().subscribe(data => {
            data.data.forEach(element => {
              if (element._id == 0) {
                that.pendingCount = element.count;
              }
              if (element._id == 1) {
                that.approveCount = element.count;
              }
              if (element._id == 2) {
                that.rejectCount = element.count;
              }
            });

          })
          if (room.approved_status == 0) {
            that.roomSer.getOnline(0, that.cur_page, that.search_txt ? that.search_txt : '').subscribe((data) => {
              that.onlinebook$ = data.data.docs;
              that.cur_page = data.data.page;
              that.totalPages = data.data.totalPages;
              that.totalcount = data.data.totalDocs;
              that.pagingCounter = data.data.pagingCounter;
            });
          }
          if (room.approved_status == 1) {
            that.roomSer.getOnline(1, that.cur_page, that.search_txt ? that.search_txt : '').subscribe((data) => {
              that.onlinebook$ = data.data.docs;
              that.cur_page = data.data.page;
              that.totalPages = data.data.totalPages;
              that.totalcount = data.data.totalDocs;
              that.pagingCounter = data.data.pagingCounter;
            });
          }
          if (room.approved_status == 2) {
            that.roomSer.getOnline(2, that.cur_page, that.search_txt ? that.search_txt : '').subscribe((data) => {
              that.onlinebook$ = data.data.docs;
              that.cur_page = data.data.page;
              that.totalPages = data.data.totalPages;
              that.totalcount = data.data.totalDocs;
              that.pagingCounter = data.data.pagingCounter;
            });
          }
        }
      })
    }, function () {
      that.toastr.error('Create action cancelled')
    });
  }

  statusChange(room, type) {
    var that = this;
    var status_txt = '';
    this.room = room;
    this.type = type;
    if (type == 1) {
      status_txt = 'approve';
      alertify.confirm('Update Status', 'Are you sure want to ' + status_txt + ' this status', function () {
        that.roomSer.statusUpdate(room._id, type, '').subscribe(
          data => {

            if (data.status.code == 0) {
              that.roomSer.getBookcount().subscribe(data => {
                data.data.forEach(element => {
                  if (element._id == 0) {
                    that.pendingCount = element.count;
                  }
                  if (element._id == 1) {
                    that.approveCount = element.count;
                  }
                  if (element._id == 2) {
                    that.rejectCount = element.count;
                  }
                });


              })
              that.toastr.success(data.status.message);

            
              if (room.approved_status == 0) {
                that.roomSer && that.roomSer.getOnline(0, that.cur_page, that.search_txt ? that.search_txt : '').subscribe((data) => {
                  that.onlinebook$ = data.data.docs;
                  that.cur_page = data.data.page;
                  that.totalPages = data.data.totalPages;
                  that.totalcount = data.data.totalDocs;
                  that.pagingCounter = data.data.pagingCounter;
                });
              }
              if (room.approved_status == 1) {
                that.roomSer && that.roomSer.getOnline(1, that.cur_page, that.search_txt ? that.search_txt : '').subscribe((data) => {
                  that.onlinebook$ = data.data.docs;
                  that.cur_page = data.data.page;
                  that.totalPages = data.data.totalPages;
                  that.totalcount = data.data.totalDocs;
                  that.pagingCounter = data.data.pagingCounter;
                });
              }
            } else {
              that.toastr.error(data.status.message)
            }
          },
          error => {
            that.toastr.error(error)

            document.body.classList.remove('jw-modal-open');
          });
      }, function () {
        that.toastr.error('Create action cancelled')
      });
    } else {
      status_txt = 'reject';
      console.log(status_txt)
      alertify.confirm('Update Status', 'Are you sure want to Reject this status', function () {
        $('#myModal4').modal('show')
      }, function () {
        that.toastr.error('Create action cancelled')
      });
    }

  }
  cancel() {
    this.submitted = false;
    this.rejectForm.reset()
  }

  viewImage(e) {
    $('#myModal7').modal('show');
    this.pan_img = e.image_file;
  }

  closeImage(e) {
    $('#myModal7').modal('hide');
  }

  download(user) {
    this.roomSer.downloadPdf(user._id).subscribe(data => {
      const blob = new Blob([data], { type: 'application/pdf' });

      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "Onlinebooking.pdf";
      link.click();
    })
  }


  submit() {
    if (this.rejectForm.invalid) {
      this.submitted = true;
      return
    }
    var that = this;
    that.roomSer.statusUpdate(this.room._id, this.type, this.f.reason.value).subscribe(
      data => {

        if (data.status.code == 0) {
          that.roomSer.getBookcount().subscribe(data => {
            data.data.forEach(element => {
              if (element._id == 0) {
                that.pendingCount = element.count;
              }
              if (element._id == 1) {
                that.approveCount = element.count;
              }
              if (element._id == 2) {
                that.rejectCount = element.count;
              }
            });

          })
          that.toastr.success(data.status.message);
          $('#myModal4').modal('hide')
          this.rejectForm.reset()
          if (this.room.approved_status == 0) {
            that.roomSer && that.roomSer.getOnline(0, that.cur_page, that.search_txt ? that.search_txt : '').subscribe((data) => {
              that.onlinebook$ = data.data.docs;
              that.cur_page = data.data.page;
              that.totalPages = data.data.totalPages;
              that.totalcount = data.data.totalDocs;
              that.pagingCounter = data.data.pagingCounter;
            });
          }
          if (this.room.approved_status == 1) {
            that.roomSer && that.roomSer.getOnline(1, that.cur_page, that.search_txt ? that.search_txt : '').subscribe((data) => {
              that.onlinebook$ = data.data.docs;
              that.cur_page = data.data.page;
              that.totalPages = data.data.totalPages;
              that.totalcount = data.data.totalDocs;
              that.pagingCounter = data.data.pagingCounter;
            });
          }
        } else {
          that.toastr.error(data.status.message)
        }
      },
      error => {
        that.toastr.error(error)

        document.body.classList.remove('jw-modal-open');
      });
  }

}
