import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
// import { HelperService, AuthenticationService, NotifyService, utilService } from '../../_services/';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/_services';
//import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';

declare var jquery: any;
declare var $: any;
declare var alertify: any;


@Component({
  selector: 'site-header',
  templateUrl: './site-header.component.html',
})
export class SiteHeaderComponent implements OnInit {
  routeParam:any;
  checkinactive:any;
  checkin_date:any;
  room_type:any;
  room_no:any;
  roomno:any;
  roomprice:any;
  reserve_date:any;
  reserveactive:any;
 constructor(public router: Router,  private route: ActivatedRoute,
  private authenticationService: AuthenticationService,) { }

  ngOnInit(): void {
    this.routeParam = this.router.url;
    //console.log(this.routeParam)

    this.route.queryParams.subscribe(params => {
      this.checkin_date=params['checkin_date'];
      this.room_type=params['room_type'];
      this.room_no = params['room_no']; 
      this.roomno=params['roomno'];
      this.roomprice=params['roomprice']
      this.reserve_date=params['reserve_date'];
    });
    //console.log(this.checkin_date)
    if(this.checkin_date){
    var str =this.checkin_date ;
var replaced = str.split(' ').join('%20');
    this.checkinactive="/checkin?checkin_date="+replaced+"&room_type="+this.room_type+"&room_no="+this.room_no+"&roomno="+this.roomno+"&roomprice="+this.roomprice;
  //console.log(this.checkinactive)
    }
    if(this.reserve_date){
  var str1 =this.reserve_date ;
  var replaced1 = str1.split(' ').join('%20');

this.reserveactive="/reservation?reserve_date="+replaced1+"&room_type="+this.room_type+"&room_no="+this.room_no+"&roomprice="+this.roomprice;

 //console.log(this.reserveactive)
    }
  }

  logout()
  {
    this.authenticationService.logout();
    this.router.navigate(['/login'])
  }




}
