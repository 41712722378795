<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
<body>
    <div class="main_container flt">
        <div class="header flt">
            <div class="row">
                <div class="col-sm-6">
                    <div class="logo flt"><img src="assets/images/logo.png"></div>
                </div>
                <div class="col-sm-6">
                    <!-- <div class="header_text flt"><button><img class="logo flt" src="assets/images/icon.png">
                            <span>Note</span></button></div> -->
                </div>
            </div>
        </div>
 
    <div class="back flt"><img src="assets/images/banner.png"></div>
    <div class="form_container flt" *ngIf="data&&status==0">
        <div class="back_head flt">Booking Details for Approval</div>
        <div class="booking_box flt">
            <div class="booking_head flt">
                <div class="booking_title">
                    Requestor Details
                </div>
                <div class="booking_date">
                    Requested on : {{data.createdAt|date:'dd-MM-yyyy'}}
                </div>
            </div>
            <div class="booking_cont flt">
                <ul>
                    <li>Name of the Requestor<span>:</span></li>
                    <li>{{data.requestor_details&& data.requestor_details.name? data.requestor_details.name : '-'}}</li>
                    <li *ngIf="data.requestor_details.role_type!='STUDENT'">Department<span>:</span></li>
                    <li *ngIf="data.requestor_details.role_type!='STUDENT'"> {{data.requestor_details.department ? data.requestor_details.department : '-'}}</li>
                    <li>Institution Name<span>:</span></li>
                    <li>{{data.requestor_details&& data.requestor_details.institution? data.requestor_details.institution : '-'}}</li>
                    <li>Contact Number<span>:</span></li>
                    <li>{{data.requestor_details&& data.requestor_details.contact_number? data.requestor_details.contact_number : '-'}}</li>
                    <li>Email<span>:</span></li>
                    <li>{{data.requestor_details&& data.requestor_details.email? data.requestor_details.email : '-'}}</li>
                    <li>Employee Code / Roll Number<span>:</span></li>
                    <li>{{data.requestor_details&& data.requestor_details.employee_code? data.requestor_details.employee_code : '-'}}</li>
                    <li>Address<span>:</span></li>
                    <li>{{data.requestor_details&& data.requestor_details.address? data.requestor_details.address : '-'}}</li>
                </ul>
                <p>GST Details</p>
                <ul>
                    <li>GST Number<span>:</span></li>
                    <li>{{data.gst_details&& data.gst_details.gst_number? data.gst_details.gst_number : '-'}}</li>
                    <li>Company Name<span>:</span></li>
                    <li>{{data.gst_details&& data.gst_details.company_name? data.gst_details.company_name : '-'}}</li>
                    <li>Address<span>:</span></li>
                    <li>{{data.gst_details&& data.gst_details.address? data.gst_details.address : '-'}}</li>
                </ul>
            </div>
        </div>

        <div class="booking_box flt">
            <div class="booking_head flt">
                <div class="booking_title">
                    Guest Details
                </div>
            </div>
            <div class="booking_cont flt">
                <ul>
                    <li>Name of the Guest<span>:</span></li>
                    <li>{{data.guest_details&& data.guest_details.name? data.guest_details.name : '-'}}</li>

                    <li>Relationship<span>:</span></li>
                    <li>{{data.guest_details&& data.guest_details.relationship? data.guest_details.relationship : '-'}}</li>

                    <li>Guest Contact Number<span>:</span></li>
                    <li>{{data.guest_details&& data.guest_details.contact_number? data.guest_details.contact_number : '-'}}</li>

                    <li>No. of Days<span>:</span></li>
                    <li>{{data.number_of_days? data.number_of_days : '-'}}</li>

                    <li>Arrival Date & TIME<span>:</span></li>
                    <li>{{(data.arrival_date? data.arrival_date: '-')|date:'dd-MM-yyyy'}} {{data.arrival_time? data.arrival_time: '-'}}</li>

                    <li>Departure Date & Time<span>:</span></li>
                    <li>{{(data.departure_date? data.departure_date: '-')|date:'dd-MM-yyyy'}} {{data.departure_time? data.departure_time: '-'}}</li>

                    <li>Guest Address<span>:</span></li>
                    <li>{{data.guest_details&& data.guest_details.address? data.guest_details.address : '-'}}</li>

                    <li>Purpose of Visit<span>:</span></li>
                    <li>{{data.purpose_of_visit? data.purpose_of_visit: '-'}}</li>
                </ul>
                <p *ngIf="data.image_file">Document</p>
                <div class="booking_file flt" *ngIf="data.image_file">
                    <img src="{{media_domain}}{{data.image_file}}" style="width:268px;height:165px"/>
                </div>
            </div>
            <div class="booking_btn flt">
                <button class="reject_btn" data-toggle="modal" data-target="#rejectModal">REJECT REQUEST</button>
                <button class="approve_btn" data-toggle="modal" data-target="#myModal" >APPROVE REQUEST</button>
            </div>
        </div>
    </div>
    <div class="form_container flt" *ngIf="!data&&status==0">
        <div class="back_head flt">Booking Details for Approval</div>
        <div class="booking_box flt">
            <div class="booking_container flt">
                <div class="no_data_img flt">
                    <img src="assets/images/no-data.png">
                </div>
                <div class="no_data_cont flt">No Data Found</div>
            </div>
        </div>

     
    </div>
    <div class="form_container flt" *ngIf="status==1">
        <div class="back_head flt">Booking Details for Approval</div>
        <div class="booking_box flt">
            <div class="booking_container flt">
                <div class="no_data_img flt">
                    <img src="assets/images/approve-icon.png">
                </div>
                <div class="no_data_cont flt">Request is already Approved</div>
            </div>
        </div>

     
    </div>
    <div class="form_container flt" *ngIf="status==2">
        <div class="back_head flt">Booking Details for Approval</div>
        <div class="booking_box flt">
            <div class="booking_container flt">
                <div class="no_data_img flt">
                    <img src="assets/images/rejected.png">
                </div>
                <div class="no_data_cont flt">Request is already Rejected</div>
            </div>
        </div>

     
    </div>
    <div class="contact flt">
        <ul>
            <li>manipal.edu, Madhav Nagar, Manipal – 576104, Karnataka, India</li>
            <li>Phone : 0820-29-23480</li>
            <li>Email : guesthouse.mahe@manipal.edu</li>
            <li>NIH Guest House : 0820-29-31640</li>
        </ul>
    </div>
    <div class="footer flt">
        <div class="foot_text flt">Designed By Chipsy IT Services PVT. LTD.</div>
    </div>
</div>
    <!-- Booking Modal -->
    <div class="modal fade booking_pop" id="myModal" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-body">
                    <div class="booking_pop_head">Are You Sure ?</div>
                    <div class="booking_pop_cont">Would You Like to Accept This Booking Request</div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="cancel_btn" data-dismiss="modal">CANCEL</button>
                    <button type="button" class="yes_btn" (click)="changestatus(1)">YES</button>
                </div>
            </div>

        </div>
    </div>

    <div class="modal fade booking_pop" id="rejectModal" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-body">
                    <div class="booking_pop_head">Are You Sure ?</div>
                    <div class="booking_pop_cont">Would You Like to Reject This Booking Request</div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="cancel_btn" data-dismiss="modal">CANCEL</button>
                    <button type="button" class="yes_btn" (click)="changestatus(2)">YES</button>
                </div>
            </div>

        </div>
    </div>
    <!-- Approve Modal -->
    <div class="modal fade approve_pop" id="myModal2" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-body">
                    <div class="approve_icon">
                        <img src="assets/images/approve-icon.png" />
                    </div>
                    <div class="approve_head">This Request Has been Approved!</div>
                    <div class="approve_cont">The booking request has been approved and a confirmation email will be sent to the requester.</div>
                    <div class="ok_btn">
                        <button (click)="close1()">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade approve_pop" id="myModal3" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-body">
                    <div class="approve_icon">
                        <img src="assets/images/rejected.png" />
                    </div>
                    <div class="approve_head">This Request Has been Rejected!</div>
                    <div class="approve_cont">The booking request has been rejected.</div>
                    <div class="ok_btn">
                        <button (click)="close1()">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</body>



<div class="modal fade reject_reason" id="myModal4" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <form [formGroup]="rejectForm" >
                <div class="reject_reason">
                    <label>Reason for Rejection: </label>
                    <textarea rows="5" formControlName="reason"> </textarea>
                    
                </div>
                <div class="invalid">
                    <div *ngIf="(f.reason.touched || submitted) && f.reason.errors?.required"
                        class="invalid-feedback">
                        <div *ngIf="f.reason.errors?.required">Reason is required</div>
                    </div>

                </div>
                </form>
                <div class="reject_butn">
                    <button class="reject_cancel" data-dismiss="modal" (click)="cancel()">Cancel</button>
                    <button class="reject_sub" (click)="submit()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>
