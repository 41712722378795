<site-header></site-header>
<div class="page-container">

</div>
<div class="page-content-wrapper">
    <div class="page-content">


        <div class="page-bar">
            <div class="page-title-breadcrumb">
                <div class="card-head">
                    <div class="page-title">Check in</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <form [formGroup]="checkinForm" role="form" (ngSubmit)="onSubmit()" #myForm="ngForm">

                    <div class="card-box">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-2">
                                    <div class="row">

                                        <div class="page_cls">User Details</div>

                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12  mt-3">
                                            <!-- <div class="maincls1 ml-5 mt-5"> -->

                                            <div class="row">


                                                <div class="col-lg-12 p-t-20">
                                                    <!-- <label style="text-align: center;">Photo</label> -->
                                                    <div class="hiddenfile">
                                                        <input name="upload" type="file" id="fileinput1"
                                                            accept="image/*" (change)="fileChangeEvent1($event)" />
                                                    </div>
                                                    <p class="ID">Photo</p>
                                                    <div class="photo">
                                                        <img alt="logo" class="img-responsive defaultimg "
                                                            src="assets/images/no_image.png" (click)="uploadImage1()"
                                                            *ngIf="!photoimage">

                                                        <img alt="logo" class="img-responsive imgid" [src]="photoimage"
                                                            (click)="uploadImage1()" *ngIf="photoimage">

                                                    </div>
                                                </div>
                                                <button class="viewbtn" *ngIf="photoimage"
                                                    (click)="vewBtn1($event)">View photo</button>
                                                <div class="col-lg-12 p-t-20">
                                                    <div class="hiddenfile">
                                                        <input name="upload" type="file" id="fileinput2"
                                                            accept="image/*" (change)="fileChangeEvent2($event)" />
                                                    </div>
                                                    <div class="ID">ID</div>
                                                    <div class="photo">
                                                        <img alt="logo" class="img-responsive defaultimg "
                                                            src="assets/images/no_image.png" (click)="uploadImage2()"
                                                            *ngIf="!idimage">

                                                        <img alt="logo" class="img-responsive imgid" [src]="idimage"
                                                            (click)="uploadImage2()" *ngIf="idimage">

                                                    </div>
                                                </div>
                                                <button class="viewbtn" *ngIf="idimage" (click)="vewBtn2($event)">View
                                                    ID</button>
                                                <div class="col-lg-12 p-t-20">
                                                    <div class="hiddenfile">
                                                        <input name="upload" type="file" id="fileinput2"
                                                            accept="image/*" (change)="fileChangeEvent3($event)" />
                                                    </div>
                                                    <div class="ID">ID</div>
                                                    <div class="photo">
                                                        <img alt="logo" class="img-responsive defaultimg "
                                                            src="assets/images/no_image.png" (click)="uploadImage3()"
                                                            *ngIf="!idimage3">

                                                        <img alt="logo" class="img-responsive imgid" [src]="idimage3"
                                                            (click)="uploadImage3()" *ngIf="idimage3">

                                                    </div>
                                                </div>
                                                <button class="viewbtn" *ngIf="idimage3" (click)="vewBtn3($event)">View
                                                    ID</button>
                                            </div>





                                            <!-- </div> -->

                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-10">
                                    <!-- <div class="mainclsci"> -->

                                    <div class="row">

                                        <div class="col-lg-3 p-t-10">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="date" id="txtRoomNo"
                                                    formControlName="cdate" onkeydown="event.preventDefault()"
                                                    autocomplete="off">
                                                <label class="mdl-textfield__label">Checkin Date</label>

                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.cdate.touched || submitted) && f.cdate.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.cdate.errors?.required">Date is required</div>
                                                </div>

                                            </div>

                                        </div>

                                        <div class="col-lg-3 p-t-10">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <!-- <input class="mdl-textfield__input" type="time" id="txtRoomNo" name="artime" formControlName="artime" [ngClass]="{ 'is-invalid': (f.artime.touched || submitted) && f.artime.errors }" placeholder="Arival Time" autocomplete="off"> -->

                                                <input class="mdl-textfield__input" id="txtRoomNo"
                                                    [ngxTimepicker]="picker1" formControlName="chekintime"
                                                    name="chekintime">
                                                <ngx-material-timepicker #picker1
                                                    (timeChanged)="setSelectedTime($event)">
                                                    <!-- [ngClass]="{'is-invalid':(f.start_time.touched || submitted) && f.start_time.errors?.required}" -->
                                                </ngx-material-timepicker>
                                                <label class="mdl-textfield__label">Checkin Time</label>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.chekintime.touched || submitted) && f.chekintime.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.chekintime.errors?.required">Checkin Time is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-lg-3 p-t-10">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="date" id="checkoutdate"
                                                    formControlName="checkoutdate" autocomplete="off">
                                                <label class="mdl-textfield__label">Checkout Date</label>

                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.checkoutdate.touched || submitted) && f.checkoutdate.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.checkoutdate.errors?.required">Checkout Date required
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="col-lg-3 p-t-10">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <!-- <input class="mdl-textfield__input" type="time" id="txtRoomNo" name="artime" formControlName="artime" [ngClass]="{ 'is-invalid': (f.artime.touched || submitted) && f.artime.errors }" placeholder="Arival Time" autocomplete="off"> -->

                                                <input class="mdl-textfield__input" id="txtRoomNo"
                                                    [ngxTimepicker]="picker2" formControlName="checkouttime"
                                                    name="artime">
                                                <ngx-material-timepicker #picker2
                                                    (timeChanged)="setSelectedTime1($event)">
                                                    <!-- [ngClass]="{'is-invalid':(f.start_time.touched || submitted) && f.start_time.errors?.required}" -->
                                                </ngx-material-timepicker>
                                                <label class="mdl-textfield__label">Checkout Time</label>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.checkouttime.touched || submitted) && f.checkouttime.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.checkouttime.errors?.required">Checkout time is
                                                        required</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 p-t-10">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                                    formControlName="phone" autocomplete="off"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    maxlength="10" (change)="valuechange($event)"
                                                    [readonly]="isReadonly">
                                                <label class="mdl-textfield__label">Phone</label>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.phone.touched || submitted) && f.phone.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.phone.errors?.required">Phone Number is required</div>
                                                </div>
                                                <div *ngIf="(f.phone.touched || submitted) && f.phone.errors?.minlength"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.phone.errors?.minlength">Minimum 10 digits are allowed
                                                    </div>
                                                </div>
                                                <!-- <div *ngIf="(f.name.touched || submitted) && f.name.errors?.pattern"
                                    class="invalid-feedback">
                                    <div *ngIf="f.name.errors?.pattern">Empty space is not allowed
                                    </div>
                                </div> -->
                                            </div>

                                        </div>



                                        <div class="col-lg-6 p-t-10">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                                    formControlName="name" autocomplete="off"
                                                    oninput="this.value = this.value.replace(/[^a-zA-Z. ]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    [readonly]="isReadonly">
                                                <label class="mdl-textfield__label">Name</label>

                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.name.touched || submitted) && f.name.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.name.errors?.required">Name is required</div>
                                                </div>
                                                <div *ngIf="(f.name.touched || submitted) && f.name.errors?.maxlength"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.name.errors?.maxlength">Maximum 50 characters are
                                                        allowed
                                                    </div>
                                                </div>
                                                <div *ngIf="(f.name.touched || submitted) && f.name.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.name.errors?.pattern">Empty space is not allowed
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 p-t-1">
                                            <!-- <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                    <input class="mdl-textfield__input" type="text" id="list3" value="" readonly
                                        tabIndex="-1">
                                    <label for="list3" class="pull-right margin-0">
                                        <i class="mdl-icon-toggle__label material-icons">keyboard_arrow_down</i>
                                    </label>
                                    <label for="list3" class="mdl-textfield__label">Document type</label>
                                    <ul data-mdl-for="list3" class="mdl-menu mdl-menu--bottom-left mdl-js-menu">
                                        <li class="mdl-menu__item" data-val="1">doc1</li>
                                        <li class="mdl-menu__item" data-val="2">doc2</li>
                                        <li class="mdl-menu__item" data-val="3">doc3</li>
                                        
                                    </ul>
                                </div> -->
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                                <label class="mdl-textfield__label" for="thing">Document type</label>
                                                <select class="mdl-textfield__input" id="thing" name="thing"
                                                    formControlName="doctype" (change)="onChange($event)">
                                                    <option value="0" disabled>Please Select Document</option>
                                                    <option value="Adharcard">Adhar card</option>

                                                    <option value="VoterId">Voter Id</option>
                                                    <option value="drivinglicense">Driving License</option>

                                                    <!-- <option *ngFor="let user of RoomType$;let i=index" value="{{user._id}}">{{user.room_type}}</option> -->

                                                </select>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.doctype.touched || submitted) && f.doctype.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.doctype.errors?.required">Document type required</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 p-t-2">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                                    formControlName="docid" autocomplete="off">
                                                <label class="mdl-textfield__label">Document ID</label>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.docid.touched || submitted) && f.docid.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.docid.errors?.required">Document ID is required</div>
                                                </div>
                                                <div *ngIf="(f.docid.touched || submitted) && f.docid.errors?.maxlength"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.docid.errors?.maxlength">Maximum 50 characters are
                                                        allowed
                                                    </div>
                                                </div>
                                                <div *ngIf="(f.docid.touched || submitted) && f.docid.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.docid.errors?.pattern">Empty space is not allowed
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 p-t-2">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                                    formControlName="gstid" autocomplete="off">
                                                <label class="mdl-textfield__label">GST ID</label>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-4 p-t-2">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text" autocomplete="off"
                                                    id="txtRoomNo" formControlName="male"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                <label class="mdl-textfield__label">Male</label>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.male.touched || submitted) && f.male.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.male.errors?.required">Male count is required</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 p-t-2">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                                    autocomplete="off" formControlName="female"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                <label class="mdl-textfield__label">Female</label>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.female.touched || submitted) && f.female.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.female.errors?.required">Female count is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 p-t-2">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                                    autocomplete="off" formControlName="children"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                <label class="mdl-textfield__label">Children</label>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.children.touched || submitted) && f.children.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.children.errors?.required">Children count is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 p-t-2">
                                            <div class="mdl-textfield mdl-js-textfield txt-full-width">
                                                <textarea class="mdl-textfield__input" rows="2" id="education"
                                                    autocomplete="off" formControlName="address"
                                                    [readonly]="isReadonly"></textarea>
                                                <label class="mdl-textfield__label" for="text7">Address</label>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.address.touched || submitted) && f.address.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.address.errors?.required">Address is required</div>
                                                </div>
                                                <div *ngIf="(f.address.touched || submitted) && f.address.errors?.maxlength"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.address.errors?.maxlength">Maximum 50 characters are
                                                        allowed
                                                    </div>
                                                </div>
                                                <div *ngIf="(f.address.touched || submitted) && f.address.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.address.errors?.pattern">Empty space is not allowed
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 p-t-2">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text" autocomplete="off"
                                                    formControlName="city" id="txtRoomNo"
                                                    oninput="this.value = this.value.replace(/[^a-zA-Z. ]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    [readonly]="isReadonly">
                                                <label class="mdl-textfield__label">city</label>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.city.touched || submitted) && f.city.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.city.errors?.required">City is required</div>
                                                </div>
                                                <div *ngIf="(f.city.touched || submitted) && f.city.errors?.maxlength"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.city.errors?.maxlength">Maximum 50 characters are
                                                        allowed
                                                    </div>
                                                </div>
                                                <div *ngIf="(f.city.touched || submitted) && f.city.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.city.errors?.pattern">Empty space is not allowed
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 p-t-2">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                                    oninput="this.value = this.value.replace(/[^a-zA-Z. ]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    formControlName="state" [readonly]="isReadonly">
                                                <label class="mdl-textfield__label">state</label>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.state.touched || submitted) && f.state.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.state.errors?.required">State is required</div>
                                                </div>
                                                <div *ngIf="(f.state.touched || submitted) && f.state.errors?.maxlength"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.state.errors?.maxlength">Maximum 50 characters are
                                                        allowed
                                                    </div>
                                                </div>
                                                <div *ngIf="(f.state.touched || submitted) && f.state.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.state.errors?.pattern">Empty space is not allowed
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 p-t-2">
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                                    autocomplete="off"
                                                    oninput="this.value = this.value.replace(/[^a-zA-Z. ]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    formControlName="country" [readonly]="isReadonly">
                                                <label class="mdl-textfield__label">country</label>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.country.touched || submitted) && f.country.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.country.errors?.required">Country is required</div>
                                                </div>
                                                <div *ngIf="(f.country.touched || submitted) && f.country.errors?.maxlength"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.country.errors?.maxlength">Maximum 50 characters are
                                                        allowed
                                                    </div>
                                                </div>
                                                <div *ngIf="(f.country.touched || submitted) && f.country.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.country.errors?.pattern">Empty space is not allowed
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 p-t-2">
                                            <div class="mdl-textfield mdl-js-textfield txt-full-width">
                                                <textarea class="mdl-textfield__input" rows="2" id="education"
                                                    formControlName="remark"></textarea>
                                                <label class="mdl-textfield__label" for="text7">Remark</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 p-t-1">
                                            <!-- <div
                                    class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                    <input class="mdl-textfield__input" type="text" id="list3" value="" readonly
                                        tabIndex="-1">
                                    <label for="list3" class="pull-right margin-0">
                                        <i class="mdl-icon-toggle__label material-icons">keyboard_arrow_down</i>
                                    </label>
                                    <label for="list3" class="mdl-textfield__label">Document type</label>
                                    <ul data-mdl-for="list3" class="mdl-menu mdl-menu--bottom-left mdl-js-menu">
                                        <li class="mdl-menu__item" data-val="1">doc1</li>
                                        <li class="mdl-menu__item" data-val="2">doc2</li>
                                        <li class="mdl-menu__item" data-val="3">doc3</li>
                                        
                                    </ul>
                                </div> -->
                                            <div
                                                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label getmdl-select getmdl-select__fix-height txt-full-width">
                                                <label class="mdl-textfield__label" for="thing">Payment Mode</label>
                                                <select class="mdl-textfield__input" id="thing" name="thing"
                                                    formControlName="payment" (change)="onChange1($event)">
                                                    <option value="0" disabled>Please Select Payment</option>
                                                    <option value="cash">Cash</option>

                                                    <option value="card">Card</option>
                                                    <option value="upipay">UPI Pay</option>

                                                    <!-- <option *ngFor="let user of RoomType$;let i=index" value="{{user._id}}">{{user.room_type}}</option> -->

                                                </select>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.payment.touched || submitted) && f.payment.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.payment.errors?.required">payment mode required</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 p-t-2">
                                            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width"
                                                id="cash">
                                                <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                                    formControlName="payid" autocomplete="off">
                                                <label class="mdl-textfield__label">Payment ID</label>
                                            </div>
                                            <div class="invalid">
                                                <div *ngIf="(f.payid.touched || submitted) && f.payid.errors?.required"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.payid.errors?.required">payment ID is required</div>
                                                </div>
                                                <div *ngIf="(f.payid.touched || submitted) && f.payid.errors?.maxlength"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.payid.errors?.maxlength">Maximum 50 characters are
                                                        allowed
                                                    </div>
                                                </div>
                                                <div *ngIf="(f.payid.touched || submitted) && f.payid.errors?.pattern"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.payid.errors?.pattern">Empty space is not allowed
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <!-- </div> -->
                                    <!-- main class ci end -->
                                </div>
                            </div>
                            <div class="row">


                                <div class="col-sm-12">
                                    <div class="row">

                                        <div class="page_cls">Room Details</div>

                                    </div>

                                    <div class="row" formArrayName="options">
                                        <div class="col-lg-12"
                                            *ngFor="let option of checkinForm.controls.options['controls']; let i=index"
                                            [formGroupName]="i">


                                            <div class="row">
                                                <div class="col-lg-2 p-t-10 ml-4">
                                                    <div
                                                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                        <select class="mdl-textfield__input" id="thing" name="thing"
                                                            name="roomtype" placeholder="Room Category"
                                                            autocomplete="off" formControlName="roomtype"
                                                            [ngClass]="{ 'is-invalid': (checkinForm.get('options')['controls'][i].get('roomtype').touched || submitted) && checkinForm.get('options')['controls'][i].get('roomtype').errors }"
                                                            (change)="roomCat($event,i,checkinForm.get('options')['controls'][i])">
                                                            <option value="" disabled>Select room Category</option>
                                                            <option *ngFor="let rm of RoomType$;let i=index"
                                                                value="{{rm._id}}">{{rm.room_type}}</option>

                                                        </select>
                                                        <label class="mdl-textfield__label">Room Category</label>

                                                    </div>
                                                    <div class="invalid1">
                                                        <div *ngIf="(checkinForm.get('options')['controls'][i].get('roomtype').touched || submitted) && checkinForm.get('options')['controls'][i].get('roomtype').errors?.required"
                                                            class="invalid-feedback">
                                                            Room Category is required
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-2 p-t-10 ml-5">
                                                    <div
                                                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">

                                                        <select class="mdl-textfield__input" id="thing{{i}}"
                                                            name="thing" name="roomnumber" placeholder="Room Number"
                                                            autocomplete="off" formControlName="roomnumber"
                                                            [ngClass]="{ 'is-invalid': (checkinForm.get('options')['controls'][i].get('roomnumber').touched || submitted) && checkinForm.get('options')['controls'][i].get('roomnumber').errors }">
                                                            <option value="" selected>please select</option>
                                                            <option *ngFor="let rm of roomDetails;let i=index"
                                                                value="{{rm._id}}">{{rm.room_number}}</option>

                                                        </select>
                                                        <label class="mdl-textfield__label">Room Number</label>

                                                    </div>
                                                    <div class="invalid1">

                                                        <div *ngIf="(checkinForm.get('options')['controls'][i].get('roomnumber').touched || submitted) && checkinForm.get('options')['controls'][i].get('roomnumber').errors?.required"
                                                            class="invalid-feedback">
                                                            Room number is required
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 p-t-10 ml-5">
                                                    <div
                                                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                        <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                            name="total" placeholder="Total Advance" autocomplete="off"
                                                            formControlName="total"
                                                            [ngClass]="{ 'is-invalid': (checkinForm.get('options')['controls'][i].get('total').touched || submitted) && checkinForm.get('options')['controls'][i].get('total').errors }">
                                                        <label class="mdl-textfield__label">Total Advance</label>

                                                    </div>
                                                    <div class="invalid1">
                                                        <div *ngIf="(checkinForm.get('options')['controls'][i].get('total').touched || submitted) && checkinForm.get('options')['controls'][i].get('total').errors?.required"
                                                            class="invalid-feedback">
                                                            Total Advance is required
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-2 p-t-10 ml-5">
                                                    <div
                                                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                        <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                                            name="roomprice" formControlName="roomprice"
                                                            autocomplete="off" readonly="true">

                                                        <label class="mdl-textfield__label">Room Price</label>
                                                    </div>

                                                </div>
                                                <div class="col-lg-2 p-t-10 ml-5">
                                                    <div
                                                        class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label txt-full-width">
                                                        <input class="mdl-textfield__input" type="text" id="txtRoomNo"
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                            name="discountamt" placeholder="Discount" autocomplete="off"
                                                            formControlName="discountamt"
                                                            [ngClass]="{ 'is-invalid': (checkinForm.get('options')['controls'][i].get('discountamt').touched || submitted) && checkinForm.get('options')['controls'][i].get('discountamt').errors }">
                                                        <label class="mdl-textfield__label">Discount</label>

                                                    </div>
                                                    <div class="invalid1">
                                                        <div *ngIf="(checkinForm.get('options')['controls'][i].get('discountamt').touched || submitted) && checkinForm.get('options')['controls'][i].get('discountamt').errors?.required"
                                                            class="invalid-feedback">
                                                            discount is required
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 p-t-10 " style="width:100%">

                                                    <div class="addroom" style="    text-align: right;">

                                                        <button type="button"
                                                            class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default"
                                                            *ngIf="i!=0" (click)="remove(i)">DELETE</button>

                                                    </div>

                                                </div>


                                            </div>



                                        </div>
                                        <div class="col-lg-2 p-t-10 ml-5" *ngIf="addrooms">
                                            <button type="button"
                                                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-primary"
                                                (click)="add_activity()">ADD ROOM</button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 p-t-20 text-center">
                                            <button type="submit"
                                                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 m-r-20 btn-info">Submit</button>
                                            <button type="button" (click)="cancel()"
                                                class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-default">Cancel</button>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>



                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
<!-- end page content -->
<!-- start chat sidebar -->
<!-- end chat sidebar -->
<site-footer></site-footer>



<div class="modal fade" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered photo_upload" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Photo Image</h5>
                <button type="button" class="close" (click)="closeModal1($event)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body ddd" *ngIf="captureimage1==false && uploadimage1==false">
                <button type="button" class="btn btn-secondary btnbox" (click)="uploadcaptureimage1(1)"><i
                        class="material-icons">camera</i><br />Capture Image</button>
                <button type="button" class="btn btn-secondary btnbox" (click)="uploadcaptureimage1(2)"><i
                        class="material-icons">file_upload</i><br />Upload File</button>
            </div>
            <div class="modal-body" *ngIf="captureimage1==true && uploadimage1==false">
                <h4 *ngIf="errors.length > 0">Messages:</h4>
                <ul *ngFor="let error of errors">
                    <li>{{error | json}}</li>
                </ul>
                <div style="display: flex;">
                    <webcam [height]="500" [width]="500" [trigger]="triggerObservable1"
                        (imageCapture)="handleImagep($event)" *ngIf="showWebcam"
                        [allowCameraSwitch]="allowCameraSwitch1" [switchCamera]="nextWebcamObservable1"
                        [videoOptions]="videoOptions1" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched1($event)"
                        (initError)="handleInitError1($event)"></webcam>
                    <br />

                </div>
                <div class="mdl">
                    <button type="button" class="btn btn-secondary" (click)="closeModal1($event)">Close</button>
                    <button type="button" class="btn btn-primary upload-btn save_btn" (click)="triggerSnapshot1();">Take A
                        Snapshot</button>
                </div>
            </div>
          
            <div class="modal-body " *ngIf="captureimage1==false && uploadimage1==true">
                <div class="uploadp">
                    <input type="file" accept="image/*" (change)="handleFileInput1($event,$event.target.files)">
                </div>
                <div class="mdl">
                    <button type="button" class="btn btn-secondary" (click)="closeModal1($event)">Close</button>
                    <button type="button" class="btn btn-primary upload-btn save_btn"
                        (click)="uploadfinalimage1()">Upload image</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-dialog-centered photo_upload" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">ID Image 1 </h5>
                <button type="button" class="close" (click)="closeModal2($event)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body ddd" *ngIf="captureimage2==false && uploadimage2==false">
                <button type="button" class="btn btn-secondary btnbox" (click)="uploadcaptureimage2(1)"><i
                        class="material-icons">camera</i><br />Capture Image</button>
                <button type="button" class="btn btn-secondary btnbox" (click)="uploadcaptureimage2(2)"><i
                        class="material-icons">file_upload</i><br />Upload File</button>
            </div>
            <div class="modal-body" *ngIf="captureimage2==true && uploadimage2==false">

                <h4 *ngIf="errors.length > 0">Messages:</h4>
                <ul *ngFor="let error of errors">
                    <li>{{error | json}}</li>
                </ul>
                <div style="display: flex;">
                    <webcam [height]="500" [width]="500" [trigger]="triggerObservable"
                        (imageCapture)="handleImage($event)" *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch"
                        [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" [imageQuality]="1"
                        (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)"></webcam>
                    <br />
                </div>

                <div class="mdl">
                    <button type="button" class="btn btn-secondary" (click)="closeModal2($event)">Close</button>
                    <button type="button" class="btn btn-primary upload-btn save_btn" (click)="triggerSnapshot();">Take
                        A Snapshot</button>
                </div>
            </div>

            <div class="modal-body " *ngIf="captureimage2==false && uploadimage2==true">
                <div class="uploadp">
                    <input type="file" accept="image/*" (change)="handleFileInput2($event,$event.target.files)">
                </div>
                <div class="mdl">
                    <button type="button" class="btn btn-secondary" (click)="closeModal2($event)">Close</button>
                    <button type="button" class="btn btn-primary upload-btn save_btn"
                        (click)="uploadfinalimage2()">Upload image</button>
                </div>
            </div>

        </div>

    </div>
</div>

<div class="modal fade" id="exampleModalCenter3" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">

    <div class="modal-dialog modal-dialog-centered photo_upload" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">ID Image 2</h5>
                <button type="button" class="close" (click)="closeModal3($event)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body ddd" *ngIf="captureimage3==false && uploadimage3==false">
                <button type="button" class="btn btn-secondary btnbox" (click)="uploadcaptureimage3(1)"><i
                        class="material-icons">camera</i><br />Capture Image</button>
                <button type="button" class="btn btn-secondary btnbox" (click)="uploadcaptureimage3(2)"><i
                        class="material-icons">file_upload</i><br />Upload File</button>
            </div>
            <div class="modal-body" *ngIf="captureimage3==true && uploadimage3==false">
                <h4 *ngIf="errors.length > 0">Messages:</h4>
                <ul *ngFor="let error of errors">
                    <li>{{error | json}}</li>
                </ul>
                <div style="display: flex;">
                    <webcam [height]="500" [width]="500" [trigger]="triggerObservable3"
                        (imageCapture)="handleImage1($event)" *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch"
                        [switchCamera]="nextWebcamObservable3" [videoOptions]="videoOptions" [imageQuality]="1"
                        (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)"></webcam>
                    <br />



                </div>
                <div class="mdl">
                    <button type="button" class="btn btn-secondary" (click)="closeModal3($event)">Close</button>
                    <button type="button" class="btn btn-primary upload-btn save_btn" (click)="triggerSnapshot3();">Take A
                        Snapshot</button>
                </div>
            </div>

            <div class="modal-body " *ngIf="captureimage3==false && uploadimage3==true">
                <div class="uploadp">
                    <input type="file" accept="image/*" (change)="handleFileInput3($event,$event.target.files)">
                </div>
                <div class="mdl">
                    <button type="button" class="btn btn-secondary" (click)="closeModal3($event)">Close</button>
                    <button type="button" class="btn btn-primary upload-btn save_btn"
                        (click)="uploadfinalimage3()">Upload image</button>
                </div>
            </div>
          
        </div>

    </div>
</div>


<div class="modal fade" id="image1show" tabindex="-1" role="dialog" aria-labelledby="image1show" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-10 mdl-hd mb-3">
                        <h4>photo</h4>
                    </div>
                    <div class="col-sm-2">
                        <button type="button" class="close" (click)="closeModal11($event)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-12 ">
                        <img class="img-responsive img_cls" [src]="photoimage" name="image" *ngIf="photoimage">
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="image2show" tabindex="-1" role="dialog" aria-labelledby="image1show" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-10 mdl-hd mb-3">
                        <h4>ID</h4>
                    </div>
                    <div class="col-sm-2">
                        <button type="button" class="close" (click)="closeModal22($event)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-12 ">
                        <img class="img-responsive img_cls" [src]="idimage" name="image" *ngIf="idimage">
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="image3show" tabindex="-1" role="dialog" aria-labelledby="image3show" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-10 mdl-hd mb-3">
                        <h4>ID</h4>
                    </div>
                    <div class="col-sm-2">
                        <button type="button" class="close" (click)="closeModal33($event)">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-sm-12 ">
                        <img class="img-responsive img_cls" [src]="idimage3" name="image" *ngIf="idimage3">
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>