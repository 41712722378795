import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService } from '../_services';
import { environment } from 'src/environments/environment';
declare var jquery: any;
declare var $: any;

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    mobile_no = '';
    activate = true;
    otp = false;
    is_auth: boolean;
    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required]],
            otp1: [''],
            otp2: [''],
            otp3: [''],
            otp4: [''],
        });

        // reset login status
        if (localStorage.getItem(`${environment.appName}` + '_user')) {
            this.is_auth = true;
            this.router.navigate(['/dashboard']);
          } else {
            this.is_auth = false;
          }

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            var that = this
            that.toastr.error('Please enter all fields')
            return;
        }

        document.body.classList.add('jw-modal-open');
        this.loading = true;
if(!this.otp)
{
        this.authenticationService.login(this.f.email.value)
            .subscribe(
                data => {
                    //console.log(data)

                    var that = this;
                    if (data.status.code == 0) {
                        that.submitted = false;
                        document.body.classList.remove('jw-modal-open');
                        that.loading = false;
                        this.otp=true;
                        this.loginForm.controls["otp1"].setValidators([Validators.required]);
                        this.loginForm.controls["otp1"].updateValueAndValidity();
                        this.loginForm.controls["otp2"].setValidators([Validators.required]);
                        this.loginForm.controls["otp2"].updateValueAndValidity();
                        this.loginForm.controls["otp3"].setValidators([Validators.required]);
                        this.loginForm.controls["otp3"].updateValueAndValidity();
                        this.loginForm.controls["otp4"].setValidators([Validators.required]);
                        this.loginForm.controls["otp4"].updateValueAndValidity();
                        that.toastr.success(data.status.message);
                        //    location.href = this.returnUrl;
                        that.router.navigateByUrl(this.returnUrl);

                    } else {
                        document.body.classList.remove('jw-modal-open');
                        that.loading = false;
                        that.toastr.error(data.status.message)
                    }
                },
                error => {

                    that.toastr.error(error)
                    document.body.classList.remove('jw-modal-open');
                    that.loading = false;
                });
            }else{
                var otp=this.f.otp1.value+ this.f.otp2.value+this.f.otp3.value+this.f.otp4.value;
                this.authenticationService.verify(this.f.email.value,otp)
            .subscribe(
                data => {
                    //console.log(data)

                    var that = this;
                    if (data.status.code == 0) {
                        that.submitted = false;
                        document.body.classList.remove('jw-modal-open');
                        that.loading = false;
                        this.otp=true;
                        that.toastr.success(data.status.message);
                        //    location.href = this.returnUrl;
                     this.router.navigate(['/dashboard'])

                    } else {
                        document.body.classList.remove('jw-modal-open');
                        that.loading = false;
                        that.toastr.error(data.status.message)
                    }
                },
                error => {

                    that.toastr.error(error)
                    document.body.classList.remove('jw-modal-open');
                    that.loading = false;
                });
            }
    }

    resendotp()
    {
        this.loginForm = this.formBuilder.group({
            email: [this.f.email.value],
            otp1: [''],
            otp2: [''],
            otp3: [''],
            otp4: [''],
        });
        this.authenticationService.login(this.f.email.value)
        .subscribe(
            data => {
                //console.log(data)

                var that = this;
                if (data.status.code == 0) {
                    that.submitted = false;
                    document.body.classList.remove('jw-modal-open');
                    that.loading = false;
                    this.otp=true;
                    this.loginForm.controls["otp1"].setValidators([Validators.required]);
                    this.loginForm.controls["otp1"].updateValueAndValidity();
                    this.loginForm.controls["otp2"].setValidators([Validators.required]);
                    this.loginForm.controls["otp2"].updateValueAndValidity();
                    this.loginForm.controls["otp3"].setValidators([Validators.required]);
                    this.loginForm.controls["otp3"].updateValueAndValidity();
                    this.loginForm.controls["otp4"].setValidators([Validators.required]);
                    this.loginForm.controls["otp4"].updateValueAndValidity();
                    that.toastr.success(data.status.message);
                    //    location.href = this.returnUrl;
                    that.router.navigateByUrl(this.returnUrl);

                } else {
                    document.body.classList.remove('jw-modal-open');
                    that.loading = false;
                    that.toastr.error(data.status.message)
                }
            },
            error => {
                var that = this;
                that.toastr.error(error)
                document.body.classList.remove('jw-modal-open');
                that.loading = false;
            });
    }
    activatenow() {

        this.authenticationService.getActive().subscribe(data => {


            //console.log(data.status.code)
            var data2: any;
            data2 = data.status.code;
            if (data2 == 0) {
                this.activate = false;

            } else {
                this.activate = true;
                this.toastr.error(data.status.meessage)
            }

        }, error => {

            this.toastr.error(error)
            document.body.classList.remove('jw-modal-open');
            this.loading = false;
        });

    }
    ngAfterViewInit() {
        $(document).on('focus', 'input,select,textarea', function (e) {
            var cur = $(this);
            $('input,select,textarea').parent().removeClass('is-focused');
            cur.parent()[0].classList.remove('is-focused');

            // cur.parent()[0].removeClass('is-focused')

            cur.parent()[0].classList.add('is-focused')
        })
    }
}
