import { Component, ViewChild, ElementRef,OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray,FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, ModalService, RoomService } from '../_services';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatePipe, formatDate, getLocaleDateTimeFormat } from '@angular/common';
import { Subject } from 'rxjs';
import { DataTablesModule } from 'angular-datatables';
import htmlToPdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { typeWithParameters } from '@angular/compiler/src/render3/util';


declare var jquery: any;
declare var $: any;
declare var html2pdf: any;
declare var alertify: any;
declare var require: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  @ViewChild('invoice') invoice:ElementRef;
checkoutForm: FormGroup;
selectTrue = false;
room_charge = false;
extra_charge = false;
laundry_charge = false;
restaurant_charge = false;
printdata = false;
submitted = false;
latest_date: any;
checkindate: any;
currentdate: any;
$checkin: any;
$checkindetails: any;
orderid: any;
checkindetails: any;
checkPhone: any;
start : any;
end: any;
checkindetailsdata: any;
checkoutdate:any;
 extraservice: any;
 checkoutdata: any;
 loading = false;
checkinData = [];
extradata = [];
items = [];
extrachargeData = [];
extrafoodchargeData = [];
extralaundrychargeData=[];
itname: any;
foodname:any;
itprice: any;
foodprice:any;
foodqty:any;
checkout_date:any;
itqry: any;
roomarray=[];
roomArr = [];
roomArr1=[];
roomIds= null;
uniqueIds=null;
itdays: any;
invoice_no: any;
invoice_date: any;
invoice_dates: any;
totalcharge: any;
charge : any;
totaldiscountamts:any;
grandtotal: any;
percent : any;
cgp : any;
cgamt: any;
sgp: any;
sgamt: any;
disper: any;
discamt : any;
cgamtf: any;
sgamtf: any;
grandfinal : any;
pricem: any;
totalm: any;
daysm: any;
grandtotalfinal : any;
datecurrent : any;
discountamount:any;
extsgst: any;
extcgst : any;
dtOptions: DataTables.Settings = {};
dtTrigger: Subject<any> = new Subject();
extrachargetotal :any;
extrafoodchargetotal:any;
extralaundrychargetotal:any;
totalchargeextra : any;
extratotalf: any;
extrafoodtotalf:any;
extralaundrytotalf:any;
extracgstamt:any;
extrasgstamt: any;
extrarotachargef : any;
extrarotafoodchargef:any;
extrarotalaundrychargef:any;
finalbalances:any;
paidadvance: any;
netamountf : any;
grandbalance : any;
fcgst: any;
fsgst: any;
disamt : any;
checkData:any;
result:any=[];
save = true;
finalbalace : any;
extraarray = [];
pdf: any;
id: any;
itrm: any;
foodroom:any;
laundry_name:any;
laundryroom:any;
laundryprice:any;
laundryquantity:any;
netamountss:any;
net_amt : any;
newdata : any;
singleextracgst : any;
singleextrasgst : any;
dispextradata=[];
dispfooddata=[];
displaundrydata=[];
   constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private modalService: ModalService,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private datepipe: DatePipe,
        private roomSer: RoomService) { }
 
  ngOnInit() {
 
this.currentdate = new Date();
this.latest_date = this.datepipe.transform(this.currentdate, 'yyyy-MM-dd');
this.datecurrent = this.datepipe.transform(this.currentdate, 'MM/dd/yyyy');
  
  	this.roomSer.getCheckin().subscribe(data =>{
  		this.$checkin = data.data.rooms;
  	})

    this.route.queryParams.subscribe(params => {
      this.orderid = params['id']; 
      this.checkout_date=params['checkout_date'];
      
    });
this.invoice_no ='';
this.invoice_dates = '';
    this.roomSer.getCheckinSingle(this.orderid).subscribe(data => {
   this.checkindetails=data.data.checkin_details[0];
      this.checkoutdate= this.checkindetails && this.checkindetails.checkout_date?this.checkindetails.checkout_date  : '';
   this.checkPhone = '';
   this.checkPhone = this.checkindetails.phone;
  

    this.roomSer.getCheckinDetails(this.checkPhone,this.checkout_date).subscribe(data =>{
    
     this.$checkindetails = data.data.rooms;
    

   

    })

   this.checkoutForm.patchValue({
    billno:this.checkindetails && this.checkindetails.bill_no?this.checkindetails.bill_no:'' ,   
   name:this.checkindetails && this.checkindetails.name?this.checkindetails.name:'' 
   })

   })

    this.roomSer.getExtraservice(this.orderid).subscribe(data =>{
     this.extraservice = data.data;
    })

    this.roomSer.getCheckout(this.orderid).subscribe(data =>{
     this.checkoutdata = data.data.checkout;
    })

  	 this.checkoutForm = this.formBuilder.group({
            billdate: [''],
            billno: [''],
        	name: [''],
          roomtariff: [''],
          discountamt:[''],
        	totalroom: [''],
        	extracharge: [''],
        	laundry: [''],
        	restaurant: [''],
        	cgst: [''],
        	sgst: [''],
        	gross: [''],
        	disc: [''],
        	discamount: [''],
        	netamount: [''],
        	paidamount: [''],
        	balance: ['']
   
        });


   
  }

 discount(e,i){
//  alert(this.checkoutForm.controls.gross.value)
  this.disper = e.target.value ? e.target.value : 0;
  var grs=0;
  // alert(this.disper)
  // alert(parseFloat(this.disper))
  // alert((parseFloat(this.checkoutForm.controls.gross.value) * parseFloat(this.disper)))
  var csk=parseFloat(this.checkoutForm.controls.totalroom.value) * parseFloat(this.disper);
  // alert(csk/100)
  grs = (this.checkoutForm.controls.gross.value) - (csk / 100)

  this.netamountss=Math.round(grs) ? Math.round(grs) : 0;
  // alert(grs)
  //  this.discamt = Math.round(this.checkoutForm.controls.gross.value - grs)
  this.discamt = csk/100;
  this.discountamount=Math.round(this.discamt) ? Math.round(this.discamt) : 0;
  //this.discamt = parseFloat(this.discamt.toFixed(2))
   this.checkoutForm.patchValue({
     discamount: Math.round(this.discamt) ? Math.round(this.discamt) : 0,
     netamount: Math.round(grs) ? Math.round(grs) : 0,
     balance:  Math.round(grs - (this.checkoutForm.controls.paidamount.value)) ? Math.round(grs - (this.checkoutForm.controls.paidamount.value)) : 0
   })  

   this.finalbalances=this.checkoutForm.controls.balance.value;
}

 selectChange(e, checkin, i, rmcat, rmno, roomCount) {

    this.percent = checkin && checkin.taxs.length > 0 && checkin.taxs[0].percentage ? checkin.taxs[0].percentage : 0;
    
    //console.log(e.target.checked)
  //  //console.log(checkin)
    this.extratotalf = 0;
    this.extrafoodtotalf=0;
    this.extralaundrytotalf=0;
    this.roomIds=[];
    this.uniqueIds=[];
    var extra = 0;
    var extrafood=0;
    var extralaundry=0;
    var lad = 0;
    var rest = 0;
    var cg = 0;
    var sg = 0;
    var ds = 1;
    var disp = this.discamt;

    this.cgp = (this.percent) / 2
    this.sgp = (this.percent) / 2
   
    var that = this;
    var isChecked = false;

     var x=0;
     var checkedItem=[];
    //  var roomArr = [];
    //  var roomIds= null;
   
     $('.checkoutcheck').each(function() {
       if ($(this).is(":checked")) {
         checkedItem.push(x);
        
   
        //  that.roomArr1.push(checkin.uniqueId)
        //  that.roomIds = [...new Set(that.roomArr)]
        //  that.uniqueIds=[...new Set(that.roomArr1)]
       }
       x++;
    });
    // if(e.target.checked==false){
    //   const index3 = that.roomArr.indexOf(checkin.room_no);
    //   that.roomArr.splice(index3,1)
    //   const index = that.roomIds.indexOf(checkin.room_no);
    //    that.roomIds.splice(index,1)

    //   const index1 = that.roomArr1.indexOf(checkin.uniqueId);
    //  that.roomArr1.splice(index1,1)

    //  const index4 = that.uniqueIds.indexOf(checkin.uniqueId);
    //  that.uniqueIds.splice(index4,1)
    //  }
     //console.error(that.roomIds)
    // //console.log(that.roomIds) ;
       that.extradata =[];
       that.checkinData=[];
       that.dispextradata=[];
       that.dispfooddata=[];
       that.displaundrydata=[];

       checkedItem.forEach(data => {
       if (typeof that.$checkindetails[data].extra_services !== 'undefined' ||that.$checkindetails[data].food_services !=='undefined' ||that.$checkindetails[data].laundry_services !=='undefined' && that.$checkindetails[data].extra_services.length > 0 || that.$checkindetails[data].food_services.length > 0 || that.$checkindetails[data].laundry_services.length > 0) {
       // this.$checkindetails[data].extra_services.percentage = this.$checkindetails[data].taxs[0].percentage;
       
        var push={
          "extra_services": this.$checkindetails[data].extra_services,
          "food_services": this.$checkindetails[data].food_services,
          "laundry_services": this.$checkindetails[data].laundry_services,
          "room_no":this.$checkindetails[data].room_no,
         "percentage":this.$checkindetails[data].taxs[0] && this.$checkindetails[data].taxs[0].percentage,
        }
        that.extradata.push(push)
        that.dispextradata.push(this.$checkindetails[data].extra_services);
        //console.log("123")
        //console.log(that.dispextradata)
        that.dispfooddata.push(this.$checkindetails[data].food_services)
        that.displaundrydata.push(this.$checkindetails[data].laundry_services)
        //console.log("1234")
        //console.log(this.$checkindetails[data])
        //console.log(that.dispfooddata)
       // that.extradata.push(this.$checkindetails[data].taxs[0].percentage);
       }


that.roomIds.push(this.$checkindetails[data].room_no)
that.uniqueIds.push(this.$checkindetails[data].uniqueId)

       this.start = this.datepipe.transform(this.$checkindetails[data].booking_date, 'MM/dd/yyyy');
       this.end = this.datepipe.transform(this.$checkindetails[data].checkout_date, 'MM/dd/yyyy');
   
   
       // To calculate the time difference of two dates 
 
       var start = new Date(that.start);
       var end = new Date(that.end);
   
       var Difference_In_Time = end.getTime() - start.getTime();
 
   //console.log(this.$checkindetails[data])

   //console.log(this.convertTime12to24(this.$checkindetails[data].checkin_time));
   //console.log(this.convertTime12to24(this.$checkindetails[data].checkout_time));
  var dt1 = new Date(this.$checkindetails[data].booking_date+" "+this.convertTime12to24(this.$checkindetails[data].checkin_time));
  var dt2 = new Date(this.$checkindetails[data].checkout_date+" "+this.convertTime12to24(this.$checkindetails[data].checkout_time));
  //  alert(this.diff_hours(dt1, dt2));

  //console.log(dt1)
  //console.log(dt2)
   var hours_calc=this.diff_hours(dt1, dt2);
      //  var days = Difference_In_Time / (1000 * 3600 * 24);
      //console.log(hours_calc)
       if(hours_calc<=72)
       {
        var day_calc= hours_calc/25;
       }else{
        var day_calc= hours_calc/24;
       }

   
  //console.log(day_calc)
    var days=Math.ceil(day_calc)
  
  
      //  if (hours_calc <= 25 ) {
      //      days = 1;
      //  } else {
      //      days = days + 1;
      //  }
       var totaltariff = days * parseFloat(rmcat.price);

       var totalamt = (totaltariff == 0) ? parseFloat(rmcat.price) : totaltariff;


       var grossamt = totalamt;

       this.cgamt = ((totaltariff * this.cgp) / 100);
       this.sgamt = ((totaltariff * this.sgp) / 100);
       var total = (rmcat.price * days) + this.cgamt + this.sgamt;
   
       var netamt = totalamt;


       var balamt = netamt - checkin.advance;



       var discamt=this.$checkindetails[data] && this.$checkindetails[data].discount_amount?this.$checkindetails[data].discount_amount:0;

        var totalprice=(this.$checkindetails[data].rooms_category[0].price-discamt)*days;
        this.cgp =this.sgp=this.cgamt=this.sgamt=0;
        if(this.$checkindetails[data].taxs.length>0){
          this.percent=this.$checkindetails[data].taxs[0].percentage;
          this.cgp = (this.percent) / 2;
          this.sgp = (this.percent) / 2;
          this.cgamt = ((totalprice * this.cgp) / 100);
          this.sgamt = ((totalprice * this.sgp) / 100);
        }
       
        var total = ((this.$checkindetails[data].rooms_category[0].price-discamt) * days) + this.cgamt + this.sgamt;
        // //console.log(total)
        that.checkinData.push({
                'id': this.$checkindetails[data]._id,
                'roomno': this.$checkindetails[data].rooms[0].room_number,
                'days': days,
                'price': this.$checkindetails[data].rooms_category[0].price,
                'totalprice':  totalprice,
                'cgstp': that.cgp ? that.cgp : 0,
                'cgst': that.cgamt.toFixed(2) ? that.cgamt.toFixed(2) : 0,
                'sgstp': that.sgp ? that.sgp : 0,
                'sgst': that.sgamt.toFixed(2) ? that.sgamt.toFixed(2) : 0,
                'total': Math.round(total) ? Math.round(total) : 0,
                'advance': this.$checkindetails[data].advance,
                'roomtype': this.$checkindetails[data].rooms_category[0].room_type,
                'roomprice': this.$checkindetails[data].rooms_category[0].price,
                'discount_amount':this.$checkindetails[data] && this.$checkindetails[data].discount_amount?this.$checkindetails[data].discount_amount:0,
        });



        // //console.log(that.checkinData)
  
     });
    


    

  //  alert(that.checkinData.length);

    var tariff = 0;
    var discountamts=0;
    var totalamts = 0;
    var grossamts = 0;
    var netamts = 0;
    var advances = 0;
    var balances = 0;
    var totdays = 0;
    var price = 0;
    var qty = 0;
 
    var checkinId = 0;
    that.extrachargeData = [];
    that.extrafoodchargeData=[];
    that.extralaundrychargeData=[];
  
  // alert(JSON.stringify(that.extradata))
    that.extradata.forEach(data => {

that.roomarray.push(data.room_no)

        that.extratotalf = 0;

        for (let val of data.extra_services) {
           //var val=value.extra_services;
            totdays = parseFloat(val.totalday)
           // 
            qty = parseFloat(val.quantity)
            price = parseFloat(val.item_name.price)
            checkinId = (price * totdays * qty)
            // //console.log(price)
            // //console.log(price)
            // //console.log("cvccxvcvxv")
            //  //console.log(checkinId);
            //alert(checkinId)
            that.itqry = val.quantity
            that.itdays = val.totalday
            that.itname = val.item_name && val.item_name.name
            that.itprice = val.item_name && val.item_name.price
            that.itrm = val.room_no.room_number
            that.extcgst = (((checkinId) * that.cgp) / 100)
            that.extsgst = (((checkinId) * that.sgp) / 100)

            this.percent = data.percentage ? data.percentage : 0;
            // //console.log(JSON.stringify(val));
          this.cgp = (this.percent) / 2;
          this.sgp = (this.percent) / 2;
          this.cgamt = ((checkinId * this.cgp) / 100);
          this.sgamt = ((checkinId * this.sgp) / 100);
          
            that.extrachargetotal = parseFloat((checkinId) + that.cgamt + that.sgamt)
         //  alert(that.extrachargetotal)
         
            that.extrachargeData.push({
                'roomno': that.itrm ? that.itrm : 0,
                'qty': that.itqry ? that.itqry : 0,
                'rate': that.itprice ? that.itprice : 0,
                'itemname': that.itname ? that.itname : 0,
                'days': that.itdays ? that.itdays : 0,
                'cgstp': that.cgp ? that.cgp : 0,
                'cgst': that.cgamt ? that.cgamt : 0,
                'sgstp': that.sgp ? that.sgp : 0,
                'sgst': that.sgamt ? that.sgamt : 0,
                'totalextra': Math.round(that.extrachargetotal) ? Math.round(that.extrachargetotal) : 0
            })
            that.totalchargeextra = (checkinId)
         }


         var foodtotalamount=0;

         that.extrafoodtotalf=0;
         for (let food of data.food_services) {
          that.foodroom= food.room_no.room_number;
          that.foodname=food.food_name && food.food_name.name ? food.food_name.name:'';
          that.foodqty=food.quantity;
          that.foodprice=food.food_name && food.food_name.price ? food.food_name.price:'';
          //console.log(that.foodname)
          foodtotalamount=food.amount;
          that.extrafoodchargetotal = foodtotalamount;
           that.extrafoodchargeData.push({
               'roomno': that.foodroom ? that.foodroom : 0,
               'qty': that.foodqty ? that.foodqty : 0,
               'rate': that.foodprice ? that.foodprice : 0,
                'foodname': that.foodname ? that.foodname : 0,
              //  'days': that.itdays ? that.itdays : 0,
              //  'cgstp': that.cgp ? that.cgp : 0,
              //  'cgst': that.cgamt ? that.cgamt : 0,
              //  'sgstp': that.sgp ? that.sgp : 0,
              //  'sgst': that.sgamt ? that.sgamt : 0,
               'totalfoodextra': Math.round(that.extrafoodchargetotal) ? Math.round(that.extrafoodchargetotal) : 0
           })
          //  that.totalchargeextra = (checkinId)
        }

          var laundrytotalamount=0;
          that.extralaundrytotalf=0;

        for (let laundry of data.laundry_services) {
          that.laundryroom= laundry.room_no.room_number;
          //console.log("hello0")
          //console.log(data.laundry_services)
          that.laundry_name=laundry.laundry_name && laundry.laundry_name.name ? laundry.laundry_name.name:'';
          that.laundryquantity=laundry.quantity;
          that.laundryprice=laundry.laundry_name && laundry.laundry_name.price ? laundry.laundry_name.price:'';
         
          
          laundrytotalamount=laundry.amount;
           that.extralaundrychargetotal = laundrytotalamount;
           that.extralaundrychargeData.push({
               'roomno': that.laundryroom ? that.laundryroom : 0,
               'qty': that.laundryquantity ? that.laundryquantity : 0,
               'rate': that.laundryprice ? that.laundryprice : 0,
                'laundry_name': that.laundry_name ? that.laundry_name : 0,
              //  'days': that.itdays ? that.itdays : 0,
              //  'cgstp': that.cgp ? that.cgp : 0,
              //  'cgst': that.cgamt ? that.cgamt : 0,
              //  'sgstp': that.sgp ? that.sgp : 0,
              //  'sgst': that.sgamt ? that.sgamt : 0,
               'totallaundryextra': Math.round(that.extralaundrychargetotal) ? Math.round(that.extralaundrychargetotal ) : 0
           })
          //  that.totalchargeextra = (checkinId)
        }

    })


  //  //console.log(that.roomarray)


    var totalextracharge = 0;
    var totalfoodextracharge=0;
    var totallaundryextracharge=0;
    var totalextacgst = 0;
    var totaextrasgct = 0;
    var totalfinal = 0
  //  //console.log(that.extrachargeData)
if(that.extrachargeData.length >0){

    that.extrachargeData.forEach(data => {
        totalextracharge += (data.days * data.qty * data.rate)

        totalextacgst += parseFloat(data.cgst)
      //  alert(data.cgst)
        totaextrasgct += parseFloat(data.sgst)
        that.extratotalf += Math.round(parseFloat(data.totalextra))
       // alert(that.extratotalf)
    })
  }


  if(that.extrafoodchargeData.length >0){

    that.extrafoodchargeData.forEach(data => {
      //   totalextracharge += (data.days * data.qty * data.rate)
      totalfoodextracharge=data.totalfoodextra;
      //   totalextacgst += parseFloat(data.cgst)
      // //  alert(data.cgst)
      //   totaextrasgct += parseFloat(data.sgst)
        that.extrafoodtotalf += Math.round(parseFloat(data.totalfoodextra))
       // alert(that.extratotalf)
    })
  }


    if(that.extralaundrychargeData.length >0){

    that.extralaundrychargeData.forEach(data => {
      //   totalextracharge += (data.days * data.qty * data.rate)
      totallaundryextracharge=data.totallaundryextra;
      //   totalextacgst += parseFloat(data.cgst)
      // //  alert(data.cgst)
      //   totaextrasgct += parseFloat(data.sgst)
        that.extralaundrytotalf += Math.round(parseFloat(data.totallaundryextra))
       // alert(that.extratotalf)
    })
  }



    // //console.log(that.extratotalf)
    that.extrarotachargef = Math.round(totalextracharge)
    that.extrarotafoodchargef=Math.round(totalfoodextracharge)
    that.extrarotalaundrychargef=Math.round(totallaundryextracharge)

    that.extracgstamt = Math.round(totalextacgst)

    that.extrasgstamt = Math.round(totaextrasgct)

    var totalprigrand = 0;

    // //console.log(that.checkinData)
    that.checkinData.forEach(data => {

        totalprigrand += parseFloat(data.total)
        tariff += parseFloat(data.price)
        discountamts +=parseFloat(data.discount_amount)
        totalamts += parseFloat(data.totalprice)

        //extra = totdays * price * qty
        extra = Math.round(that.extratotalf)
        extrafood=Math.round(that.extrafoodtotalf)
        extralaundry=Math.round(that.extralaundrytotalf)
       // //console.log(extra)
      //  //console.log("cvccxvcvxv")
        cg += parseFloat(data.cgst)
        sg += parseFloat(data.sgst)
        grossamts = Math.round(totalamts + extra +extrafood+extralaundry+lad + rest + cg + sg)

        netamts = grossamts
        advances += parseFloat(data.advance);
        balances = netamts - advances
    })
    that.paidadvance = advances

    that.pricem = price
    that.totalm = totdays
    that.daysm = qty
    that.grandtotalfinal = Math.round(totalprigrand)

    var grndextr = 0;
    grndextr = that.grandtotalfinal ? that.grandtotalfinal : 0 + Math.round(that.extratotalf) ? Math.round(that.extratotalf) : 0 + Math.round(that.extrafoodtotalf) ? Math.round(that.extrafoodtotalf):0 + Math.round(that.extralaundrytotalf) ? Math.round(that.extralaundrytotalf):0
    var net = 0
    net = grndextr


    that.netamountf = net

    that.grandbalance = (that.netamountf ? that.netamountf : 0) - that.paidadvance

    that.totalcharge = totalamts;
    that.charge = tariff;
    that.totaldiscountamts=discountamts;
    that.cgamtf = Math.round(cg);
    that.sgamtf = Math.round(sg);
    that.grandtotal = Math.round(that.totalcharge)
    that.grandfinal = Math.round((that.grandtotal) + (that.pricem * that.totalm * that.daysm))
    const checked = e.target.checked;
    if (checked) {

        $('.case' + i).prop('checked', true);
        this.selectTrue = true;


    } else {


        // $('.case' + i).prop('checked', false);
        // this.selectTrue = true;
        // var that = this;



        // const index = that.checkinData.findIndex(val => val.id == checkin._id);
        // const selectedCheckinData = that.checkinData.find(val => val.id == checkin._id);
        // //console.log(checkin.rooms)
        // var extranew = [];
        // var extraindex = that.extrachargeData.findIndex(val => val.roomno == checkin.rooms[0].room_number);
        // const selectExtra = that.extrachargeData.find(val => {
        //     if (val.roomno == checkin.rooms[0].room_number) {
        //         extranew.push(val)
        //     }
        // })
        // //console.log(that.extrachargeData)
        // that.extrachargeData.splice(extraindex, 1);

        // that.checkinData.splice(index, 1);
        // /*that.extradata.splice(index,1);*/

        // for (let val of extranew) {

        //     that.extratotalf -= Math.round(val.totalextra)
        // }



        // totalprigrand -= parseFloat(selectedCheckinData.total)
        // tariff -= parseFloat(selectedCheckinData.price)
        // totalamts -= parseFloat(selectedCheckinData.totalprice)

        // //extra = totdays * price * qty
        // extra = Math.round(that.extratotalf) ? Math.round(that.extratotalf) : 0

        // cg -= parseFloat(selectedCheckinData.cgst)
        // sg -= parseFloat(selectedCheckinData.sgst)
        // grossamts = Math.round(totalamts + extra + lad + rest + cg + sg)
        // var disc_amount = 0
        // if (that.disper) {
        //     disc_amount = (grossamts / 100) * (parseFloat(that.disper))
        //     //console.log(grossamts)
        //     //console.log(disc_amount)
        //     netamts = Math.round(grossamts - disc_amount)
        // } else {
        //     netamts = Math.round(grossamts)
        // }

        // advances -= Math.round(checkin.advance)
        // balances = Math.round(netamts - advances)

        //  })




    }


    that.finalbalace = (that.netamountf - (that.discamt ? that.discamt : 0) + 0.41 - that.paidadvance ? that.netamountf - (that.discamt ? that.discamt : 0) + 0.41 - that.paidadvance : 0).toFixed(2);

    that.checkoutForm.patchValue({
        roomtariff: tariff ? tariff : 0,
        discountamt:discountamts?discountamts:0,
        totalroom: totalamts ? totalamts : 0,
        extracharge: Math.round(extra) ? Math.round(extra) : 0,
        laundry: Math.round(extralaundry)? Math.round(extralaundry): 0,
        restaurant: Math.round(extrafood)? Math.round(extrafood) : 0,
        cgst: cg ? cg : 0,
        sgst: sg ? sg : 0,
        gross: Math.round(grossamts) ? Math.round(grossamts) : 0,
        disc: that.disper ? that.disper : 0,
        discamount: Math.round(that.disper) ? (totalamts / 100) * (parseFloat(that.disper)) : 0,
        netamount: Math.round(that.disper) ? Math.round(netamts)-((totalamts / 100) * (parseFloat(that.disper))) :  Math.round(netamts),
        paidamount: advances ? advances : 0,
        balance: Math.round(that.disper) ? Math.round(balances)-((totalamts / 100) * (parseFloat(that.disper))) : Math.round(balances)
    })

    if (that.checkinData.length == 0) {
        // that.checkoutForm.reset();
        that.selectTrue = false;
    }

    this.netamountss=this.checkoutForm.controls.gross.value;
    this.finalbalances=this.checkoutForm.controls.balance.value;

}

 convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
}

diff_hours(dt2, dt1) 
 {

  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= (60 * 60);
  return Math.abs(Math.round(diff));
  
 }
  arrow(e){
      e.stopPropagation();
 	 this.room_charge = !this.room_charge;
 }

 arrow1(e){
     e.stopPropagation();
    this.room_charge = !this.room_charge;
   
 }


 cancel(){
  //  location.href = "/front-desk";
  this.router.navigateByUrl('/front-desk');
 }

 extracharge(e){
   e.stopPropagation();
 	this.extra_charge = !this.extra_charge;
 }

 extracharge1(e){
     e.stopPropagation();
   this.extra_charge = !this.extra_charge;
 }

 laundryCharge(e){
    e.stopPropagation();
 	this.laundry_charge = !this.laundry_charge;
 }

 laundryCharge1(e){
    e.stopPropagation();
   this.laundry_charge = !this.laundry_charge;
 }

 restcharge(e){
    e.stopPropagation();
 	this.restaurant_charge = !this.restaurant_charge;
 }

 restcharge1(e){
    e.stopPropagation();
   this.restaurant_charge = !this.restaurant_charge;
 }

 formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('-');
}

printDiv(divName) {
  var printContents = document.getElementById(divName).innerHTML;
  var originalContents = document.body.innerHTML;

  document.body.innerHTML = printContents;

  window.print();

  document.body.innerHTML = originalContents;
}
dateFormat (date, fstr, utc) {
  utc = utc ? 'getUTC' : 'get';
  return fstr.replace (/%[YmdHMS]/g, function (m) {
    switch (m) {
    case '%Y': return date[utc + 'FullYear'] (); // no leading zeros required
    case '%m': m = 1 + date[utc + 'Month'] (); break;
    case '%d': m = date[utc + 'Date'] (); break;
    case '%H': m = date[utc + 'Hours'] (); break;
    case '%M': m = date[utc + 'Minutes'] (); break;
    case '%S': m = date[utc + 'Seconds'] (); break;
    default: return m.slice (1); // unknown code, remove %
    }
    // add leading zero if required
    return ('0' + m).slice (-2);
  });
}

  get f() { return this.checkoutForm.controls; }
  onSubmit(form,formWrap,chec){

  	this.submitted = true;
  	if (this.checkoutForm.invalid) {
            var that = this
            that.toastr.error('Please enter all fields')
            return;
        }

         Swal.fire({
  title: 'Are you sure want to checkout this room?',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No'
}).then((result)=>{

  if(result.isConfirmed){
    var timestamp  = new Date().valueOf().toString();
    var that = this;
    that.roomSer.checkout(that.roomIds,
      that.uniqueIds,
      that.checkPhone,
      chec.name,
      that.latest_date,
      chec.bill_no ? chec.bill_no : '',
      that.f.roomtariff.value,
      that.f.totalroom.value,
      that.f.extracharge.value,
      that.f.laundry.value,
      that.f.restaurant.value,
      that.f.cgst.value,
      that.f.sgst.value,
      that.f.gross.value,
      that.f.disc.value,
      that.f.discamount.value,
      that.f.netamount.value,
      that.f.paidamount.value,
      that.f.balance.value,
      
      
      chec._id,timestamp).subscribe(data=>{

          if (data.status.code == 0) {
                         setTimeout(function () {
                          $('#bill').hide();
                          $('#invoice').show();
            that.invoice_no = data.data[0].invoice_no;
                          that.invoice_date = data.data[0].createdAt;
                          
                          
                        
                        
                          var date1 = new Date(that.invoice_date);
                        var d=  date1.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit',hour12: true });
                        var dt=d.toUpperCase();
                     
                      
                       
                          that.invoice_dates=that.formatDate(that.invoice_date);
            $('#invoice_no').text(that.invoice_no)
            $('#invoice_dates').text(that.invoice_dates+' '+dt)
            $('#invoice_dates1').text(that.invoice_dates+' '+dt)
                        
                        that.id = data.data[0].invoice_no;
                         
                        var newdata = $('#invoice').html()
                      
                          that.pdf = JSON.stringify(newdata)
                         
                      that.pdfData()

                    
              }, 1000);
                      
                            document.body.classList.remove('jw-modal-open');
                            that.loading = false;
                            that.toastr.success(data.status.message);
                           that.printdata = true;
                           that.save = false;
                          
                           
                        } else {
                            document.body.classList.remove('jw-modal-open');
                            that.loading = false;
                            that.toastr.error(data.status.message)
                        }
                       
              },
                error => {

                    that.toastr.error(error)
                    document.body.classList.remove('jw-modal-open');
                    that.loading = false;
                });
         }else if (result.isDenied) {
                Swal.fire(
                  'Cancelled!',
                )
              }
             });
	}


 pdfData(){


     this.roomSer.updatepdf(
              this.id,
  this.pdf).subscribe(data=>{
if(data.status.code==0){
  var that = this;
 
  
}
            })
  
}

openPDF(chkn) {
  // $('#invoice').show();
    const element = document.getElementById("invoice");
    //console.log(element)
 let opt = {
        margin: [5, 0, 5, 0],
        enableLinks: true,
        filename: 'someName',
        type: 'PNG', quality: 5
      };
      html2pdf().from(element).set(opt).toPdf().get('pdf').then(function (pdf) {
      window.open(pdf.output('bloburl'), '_blank');
      pdf.addPage();


  }); 

  }



  openpdff(){

   var printContents = document.getElementById('invoice').innerHTML;
var popupWin = window.open('_blank', '', 'top=0,left=0,height=100%,width=auto');
popupWin.document.open();
popupWin.document.write("\n      <html>\n        <head>\n                </head>\n    <body onload=\"window.print();window.close()\">" + printContents + "</body>\n      </html>");
popupWin.document.close();
  }
  

  ngAfterViewInit(){
    $(document).on('focus','input,select,textarea',function(e){ 
      var cur = $(this);
      $('input,select,textarea').parent().removeClass('is-focused');
      cur.parent()[0].classList.remove('is-focused');
      
      // cur.parent()[0].removeClass('is-focused')
      
        cur.parent()[0].classList.add('is-focused')
       })
    $('#invoice').hide();
//     printContents = document.getElementById(this.printSectionId).innerHTML;
// popupWin = window.open('_blank', '', 'top=0,left=0,height=100%,width=auto');
// popupWin.document.open();
// popupWin.document.write("\n      <html>\n        <head>\n          <title>" + (this.printTitle ? this.printTitle : '') + "</title>\n          <style>\n            " + this.returnStyleValues() + "\n          </style>\n        </head>\n    <body onload=\"window.print();window.close()\">" + printContents + "</body>\n      </html>");
// popupWin.document.close();
setTimeout(()=>{

     //  $('#invoice').hide();
},1000)
    




  }
}
