import { Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { AlertService, ModalService, RoomService } from '../_services';
import {HomeService} from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { DatePipe, formatDate, getLocaleDateTimeFormat } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2'; 
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  currentdate: any;
  loading = false;
roomDate: any;
roomDetails: any;
latest_date: any;
checkindate: any;
room : any;
rmdate: any;
changedate: any;
room_unique_id:any;
reservationRoom$:any;
checkinRoom$:any;
roomDetails$:any;
reservename:any;
reservephone:any;
reservestatus:any;
checkinId:any;
makedate = false;
checkin = false;
submitted=false;
checkinData = [];
reservationId = [];
reserve_checkin=false;
chekinusername:any;
checkinphone:any;
checkindetails$:any;
checkinstatus:any;
checkinsid=[];
checkin_count:any;
reservation_count:any;
block_count:any;
maintanance_count:any;
dtOptions: DataTables.Settings = {};
dtTrigger: Subject<any> = new Subject();
dirty_count:any;
updated:any;
synchbutton=false;
convertfile:any;
  constructor(
    private roomSer: RoomService,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentdate = new Date();
this.latest_date = this.datepipe.transform(this.currentdate, 'yyyy-MM-dd');
this.changedate=this.datepipe.transform(this.currentdate, 'yyyy-MM-dd');

this.roomSer.getSync().subscribe(data => {
  this.updated=data.last_sync;
  this.synchbutton=true;

  //console.log(this.updated)
});

// this.roomSer.getbokingsss().subscribe(data => {

// this.convertfile=data.data.Items;



 
// });


this.roomSer.getDashboardchk(this.latest_date).subscribe(data => {

  this.checkin_count=data.data.checkin_count;


});
this.roomSer.getDashboardres(this.latest_date).subscribe(data => {

this.reservation_count=data.data.reservation_count;


});
this.roomSer.getDashboardblk(this.latest_date).subscribe(data => {


this.block_count=data.data.block_count;


});
this.roomSer.getDashboardmain(this.latest_date).subscribe(data => {


this.maintanance_count=data.data.maintanance_count;

});
this.roomSer.getDashboarddirty(this.latest_date).subscribe(data => {


  this.dirty_count=data.data.dirty_count;
  
  });
  }
  dateChange(e){
    this.changedate = '';
    this.changedate = e.target.value;
     this.roomSer.getDashboardchk(this.changedate).subscribe(data => {

            this.checkin_count=data.data.checkin_count;
        
  
        });
        this.roomSer.getDashboardres(this.changedate).subscribe(data => {

          this.reservation_count=data.data.reservation_count;
         

      });
      this.roomSer.getDashboardblk(this.changedate).subscribe(data => {

      
        this.block_count=data.data.block_count;
       

    });
    this.roomSer.getDashboardmain(this.changedate).subscribe(data => {

     
      this.maintanance_count=data.data.maintanance_count;

  });
  this.roomSer.getDashboarddirty(this.changedate).subscribe(data => {


    this.dirty_count=data.data.dirty_count;
    
    });
    }
    upload(e){
      this.convertfile.forEach(element => {
        // //console.log(element.photo_image);
        var base64 = element.idimage3;
        var imageName = 'name.jpeg';
        //console.log(base64)
        if(base64){
          var id=element._id;

        var imageBlob = this.dataURItoBlob(base64);
 
        var imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
//console.log(imageFile);
this.roomSer.updatebookingss(id,imageFile).subscribe(
      data => {
         if (data.status.code == 0) {
         this.toastr.success("success")
              } else {
                
              }
      },
      error => {

         
      });

        }
      });


    }
    dataURItoBlob(dataURI) {
      var binary = atob(dataURI.split(',')[1]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], {
        type: 'image/jpeg'
      });
    }
    reportpage(e)
    {
       this.router.navigateByUrl('/occupied-report?date='+this.changedate);

    }
    reportpage1(e)
    {
       this.router.navigateByUrl('/reserved-report?date='+this.changedate);

    }

    sync(e){
      Swal.fire({
        title: 'Are you sure want to sync?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result) => {
      if(result.isConfirmed){
        var that = this;
    that.loading=true;
        that.roomSer.getSyncdb().subscribe(
              data => {
                 if (data.status.code == 0) {
                  that.loading=false;
                          that.submitted = false;
                          document.body.classList.remove('jw-modal-open');
                          that.loading = false;
                          that.toastr.success(data.status.message);
                          // that.checkinForm.setValue({
                           
                          //   food_name:'',
                          //   quantity:'',
                          //   rate:'',
                          //   amount:'',
                          // })
                    
                          //formWrap.reset();
                          setTimeout(function () {
                            $("#support_table5").dataTable().fnDestroy();
                            //that.roomSer.getFoodservice(that.uniqueId).subscribe(data=>{
                  
                              //console.log(data)
                            //  that.foodservice$=data.data;
                              that.dtTrigger.next();
                              })
                         // }, 1000);
                      } else {
                          document.body.classList.remove('jw-modal-open');
                          that.loading = false;
                          that.toastr.error(data.status.message)
                      }
              },
              error => {
  
                  that.toastr.error(error)
                  document.body.classList.remove('jw-modal-open');
                  that.loading = false;
              });
    
    
        }
        else if (result.isDenied) {
          Swal.fire(
            'Cancelled!',
          )
        }
       });
  
  
    }


}
