import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class RoomService {
  constructor(private http: HttpClient) { }
  getregistereddata(id: any, token: any,refid:any): Observable<any> {
    
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/hradmin/singleregistration-view/` + id + '/'+token,{}).pipe(map(user => user));
  }

  approveregistration(id: any, token: any,refid:any,status:any,reason:any): Observable<any> {
    let formData={
      'approved_status':status,
      'token':token,
      'refid':refid,
      'reject_reason':reason
    }
    
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/hradmin/update-registration/` + id ,formData).pipe(map(user => user));
  }

  getOnline(approved_status: any, page: any, search: any): Observable<any> {
    var search;
    if (search) {
      search = search;
    } else {
      search = ''
    }
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/hradmin/order_status?approved_status=` + approved_status + '&page=' + page + '&search=' + search).pipe(map(user => user));
  }

  getBookcount(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/hradmin/order_count`).pipe(map(user => user));
  }

  deleteRoom(id: any) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/delete/` + id).pipe(map(user => {
      return user;
    }));
  }

  getRooms(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-rooms`).pipe(map(user => user));
  }

  getRoomsALL(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/active-rooms`).pipe(map(user => user));
  }
  getSync(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/syncing/last-sync`).pipe(map(user => user));
  }
  getSyncdb(): Observable<any> {
    var formdata = {}
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/syncing/db`, formdata).pipe(map(data => {
      return data;
    }));
  }


  getRoomdate(date: any): Observable<any> {
    var formData = {
      'selected_date': date
    }

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-rooms?` + $.param(formData)).pipe(map(user => user));
  }

  getDashboardchk(date: any): Observable<any> {
    var formData = {
      'selected_date': date
    }

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-checkincount?` + $.param(formData)).pipe(map(user => user));
  }
  getDashboardres(date: any): Observable<any> {
    var formData = {
      'selected_date': date
    }

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-reservationcount?` + $.param(formData)).pipe(map(user => user));
  }
  getDashboardblk(date: any): Observable<any> {
    var formData = {
      'selected_date': date
    }

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-blockcount?` + $.param(formData)).pipe(map(user => user));
  }
  getDashboardmain(date: any): Observable<any> {
    var formData = {
      'selected_date': date
    }

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-maintainancecount?` + $.param(formData)).pipe(map(user => user));
  }

  getDashboarddirty(date: any): Observable<any> {
    var formData = {
      'selected_date': date
    }

    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-dirtycount?` + $.param(formData)).pipe(map(user => user));
  }

  getroomData(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/getRoomsByRoomCategory/` + id).pipe(map(user => user));
  }


  getroomType(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-rooms/` + id).pipe(map(user => user));
  }

  statusUpdate(id: any, status: any,reason:any) {
    var formData = { 'approved_status': status, 'reject_reason':reason }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/hradmin/update_status/` + id, formData).pipe(map(user => {
      return user;
    }));
  }



  downloadPdf(id: any): Observable<any> {
    return this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/download-register/` + id, { responseType: 'blob' as 'json' }).pipe(map(user => {
      console.log("user", user);
      return user
    }));
  }

  reservation(room_no: any, reservationdate: any,
    phone: any,
    name: any,
    gender: any,
    ardate: any,
    artime: any,
    drdate: any,
    drtime: any,
    address: any, city: any, state: any, country: any, male: any, female: any, children: any, remark: any, options, status: any) {
    var formData = {
      'room_no': room_no,
      'reservation_date': reservationdate,
      'phone': phone,
      'name': name,
      'gender': gender,
      'arrival_date': ardate,
      'arrival_time': artime,
      'departure_date': drdate,
      'departure_time': drtime,
      'address': address,
      'city': city,
      'state': state,
      'country': country,
      'male': male,
      'female': female,
      'children': children,
      'remark': remark,
      'room_details': options,
      'status': status

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/reserve-room`, formData).pipe(map(user => {
      return user;
    }));
  }

  getReservation(params): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-reservations?page=` + params.page + `&search=` + params.search).pipe(map(user => user));
  }


  delete(id: any) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/delete-rooms/` + id).pipe(map(user => {
      return user;
    }));
  }

  saveCheckIn(reservId: any, roomId: any, status: any) {
    var formData = {
      'reservation_ids': reservId,
      'room_no_ids': roomId,
      'status': status
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/updateReservation`, formData).pipe(map(data => {
      return data;
    }));
  }


  getCheckin(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-checkin`).pipe(map(user => user));
  }


  getCheckinDetails(phone: any, checkout_date: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/checkout-details-mobile?phone=` + phone + '&status=' + 3 + '&checkout_date=' + checkout_date).pipe(map(user => user));
  }

  getCheckinSingle(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/checkin-details/` + id).pipe(map(user => user));
  }
  getCheckinExtra(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-checkins/` + id).pipe(map(user => user));
  }

  getExtraservice(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/extra-services?uniqueId=` + id).pipe(map(user => user));
  }
  getFoodservice(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/food-services?uniqueId=` + id).pipe(map(user => user));
  }
  getLaundryservice(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/laundry-services?uniqueId=` + id).pipe(map(user => user));
  }
  getUserDetails(phone: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-user-details?phone=` + phone).pipe(map(user => user));
  }



  getItems(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/item`).pipe(map(user => user));
  }
  getItem(): Observable<any> {


    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-item`).pipe(map(user => user));
  }


  getReservationDetails(phone: any, status: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/checkout-details?phone=` + phone + '&status=' + status).pipe(map(user => user));
  }



  checkout(room_no: any,
    uniqueIds: any,
    phone: any,
    name: any,
    bill_date: any,
    bill_no: any,
    room_tariff: any,
    total_room: any,
    extra_charge: any,
    laundry: any, restaurant: any, cgst: any, sgst: any, gross: any, disc: any,
    disc_amount: any, net_amount: any, paid_amount: any, balance: any,
    checkinId: any, timestamp: String) {
    var formData = {
      'room_no': room_no,
      'uniqueIds': uniqueIds,
      'phone': phone,
      'name': name,
      'bill_date': bill_date,
      'bill_no': bill_no,
      'room_tariff': room_tariff,
      'total_room': total_room,
      'extra_charge': extra_charge,
      'laundry': laundry,
      'restaurant': restaurant,
      'cgst': cgst,
      'sgst': sgst,
      'gross': gross,
      'disc': disc,
      'disc_amount': disc_amount,
      'net_amount': net_amount,
      'paid_amount': paid_amount,
      'balance': balance,
      'checkinId': checkinId,
      'uniqetime': timestamp

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/checkout`, formData).pipe(map(user => {
      return user;
    }));

  }

  getCheckout(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get_checkout/` + id).pipe(map(user => user));
  }

  blockRoom(id: any, booking_date: any, status: any) {
    var formData = {
      'booking_date': booking_date,
      'status': status,

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/update/room-status/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  blockRoom1(id: any, booking_date: any, room_type: any, status: any, checkinId: any) {
    var formData = {
      'booking_date': booking_date,
      'room_type': room_type,
      'status': status,
      'checkinId': checkinId,

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/update/room-status/` + id, formData).pipe(map(user => {
      return user;
    }));
  }

  cancelcheckin(uniqueId: any) {
    var formData = {
      'uniqueId': uniqueId

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/cancel-checkins`, formData).pipe(map(user => {
      return user;
    }));
  }


  getroomCategory(id: any, booking_date: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-rooms-by-category/` + id + '?booking_date=' + booking_date).pipe(map(user => user));
  }

  getroomtype(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/rooms-type/` + id).pipe(map(user => user));
  }

  cancelreservation(cancelreservation) {
    var formData = {
      'cancelreservation': cancelreservation,
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/cancel-reservation`, formData).pipe(map(user => {
      return user;
    }));
  }


  checkin(
    cdate: any,

    phone: any,
    name: any,
    doctype: any,
    docid: any,
    male: any,
    female: any,
    children: any,

    address: any,
    city: any,
    state: any,
    country: any,

    remark: any,
    payment: any,
    payid: any,
    options: any,
    fileToUpload1: any,
    fileToUpload2: any,
    status,
    checkoutdate: any,
    checkin_time: any,
    checkout_time: any,
    gstid: any,
    idimage3: any
  ) {

    // var formData = {

    //   'cdate': cdate,
    //   'phone': phone,
    //   'name': name,
    //   'document_type': doctype,
    //   'document_id': docid,

    //   'address': address,
    //   'city': city,
    //   'state': state,
    //   'country': country,
    //   'male': male,
    //   'female': female,
    //   'children': children,
    //   'remark': remark,
    //   'payment_mode': payment,

    //   'payid': payid,
    //   'options':options,
    //   'photo_image':fileToUpload1,
    //   'id_image':fileToUpload2,
    //   'status':status,
    //   'checkout_date':checkoutdate,
    //   'checkin_time': checkin_time,
    //   'checkout_time':checkout_time,
    //   'gstid':gstid,
    //   'idimage3':idimage3
    //   }


    const formData: FormData = new FormData();

    formData.append('cdate', cdate);
    formData.append('phone', phone);
    formData.append('name', name);
    formData.append('document_type', doctype);
    formData.append('document_id', docid);
    formData.append('address', address);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('country', country);
    formData.append('male', male);
    formData.append('female', female);
    formData.append('children', children);
    formData.append('remark', remark);
    formData.append('payment_mode', payment);
    formData.append('payid', payid);
    formData.append('options', JSON.stringify(options));
    if (fileToUpload1) {
      formData.append('photo_image', fileToUpload1);
    }
    if (fileToUpload2) {
      formData.append('id_image', fileToUpload2);
    }
    formData.append('status', status);
    formData.append('checkout_date', checkoutdate);
    formData.append('checkin_time', checkin_time);
    formData.append('checkout_time', checkout_time);
    formData.append('gstid', gstid);
    if (idimage3) {
      formData.append('idimage3', idimage3);
    }



    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/book-room`, formData).pipe(map(data => {
      return data;
    }));
  }

  reservationcheckin(
    cdate: any,

    phone: any,
    name: any,
    doctype: any,
    docid: any,
    male: any,
    female: any,
    children: any,

    address: any,
    city: any,
    state: any,
    country: any,

    remark: any,
    payment: any,
    payid: any,
    options: any,
    fileToUpload1: any,
    fileToUpload2: any,
    status,
    checkoutdate: any,
    uniqueId: any,
    ardate: any,
    artime: any,
    drdate: any,
    drtime: any,
    checkin_time: any,
    checkout_time: any,
    idimage3: any,
  ) {

    // var formData = {

    //   'cdate': cdate,
    //   'phone': phone,
    //   'name': name,
    //   'document_type': doctype,
    //   'document_id': docid,  
    //   'address': address,
    //   'city': city,
    //   'state': state,
    //   'country': country,
    //   'male': male,
    //   'female': female,
    //   'children': children,
    //   'remark': remark,
    //   'payment_mode': payment,   
    //   'payid': payid,
    //   'options':options,
    //   'photo_image':fileToUpload1,
    //   'id_image':fileToUpload2,
    //   'status':status,
    //   'checkout_date':checkoutdate,
    //   'uniqueId':uniqueId,
    //   'arrival_date': ardate,
    //   'arrival_time': artime,
    //   'departure_date': drdate,
    //   'departure_time': drtime,
    //   'checkin_time': checkin_time,
    //   'checkout_time':checkout_time,
    //   'idimage3':idimage3,
    //   }


    const formData: FormData = new FormData();

    formData.append('cdate', cdate);
    formData.append('phone', phone);
    formData.append('name', name);
    formData.append('document_type', doctype);
    formData.append('document_id', docid);
    formData.append('address', address);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('country', country);
    formData.append('male', male);
    formData.append('female', female);
    formData.append('children', children);
    formData.append('remark', remark);
    formData.append('payment_mode', payment);
    formData.append('payid', payid);
    formData.append('options', JSON.stringify(options));
    formData.append('status', status);
    formData.append('checkout_date', checkoutdate);
    formData.append('checkin_time', checkin_time);
    formData.append('checkout_time', checkout_time);
    //  formData.append('gstid', gstid);
    formData.append('arrival_date', ardate);
    formData.append('arrival_time', artime);
    formData.append('departure_date', drdate);
    formData.append('departure_time', drtime);
    formData.append('uniqueId', uniqueId);

    if (idimage3) {
      formData.append('idimage3', idimage3);
    }
    if (fileToUpload1) {
      formData.append('photo_image', fileToUpload1);
    }
    if (fileToUpload2) {
      formData.append('id_image', fileToUpload2);
    }

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/reservation-to-checkin`, formData).pipe(map(data => {
      return data;
    }));
  }




  updatecheckin(
    id: any,
    cdate: any,

    phone: any,
    name: any,
    doctype: any,
    docid: any,
    male: any,
    female: any,
    children: any,

    address: any,
    city: any,
    state: any,
    country: any,

    remark: any,
    payment: any,
    payid: any,
    options: any,
    fileToUpload1: File,
    fileToUpload2: File,
    status,
    checkoutdate: any,
    uniqueId: any,
    bill_no: any,
    checkin_no: any,
    checkin_time: any,
    checkout_time: any,
    gstid: any,
    idimage3: File,
  ) {

    // var formData = {

    //   'cdate': cdate,
    //   'phone': phone,
    //   'name': name,
    //   'document_type': doctype,
    //   'document_id': docid,
    //     'gstid':gstid,
    //   'address': address,
    //   'city': city,
    //   'state': state,
    //   'country': country,
    //   'male': male,
    //   'female': female,
    //   'children': children,
    //   'remark': remark,
    //   'payment_mode': payment,

    //   'payid': payid,
    //   'options':options,
    //   'photo_image':fileToUpload1,
    //   'id_image':fileToUpload2,
    //   'status':status,
    //   'checkout_date':checkoutdate,
    //   'uniqueId':uniqueId,
    //   'bill_no':bill_no,
    //   'checkin_no':checkin_no,
    //   'checkin_time': checkin_time,
    //   'checkout_time': checkout_time,
    //   'idimage3':idimage3
    //   }

    const formData: FormData = new FormData();

    formData.append('cdate', cdate);
    formData.append('phone', phone);
    formData.append('name', name);
    formData.append('document_type', doctype);
    formData.append('document_id', docid);
    formData.append('address', address);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('country', country);
    formData.append('male', male);
    formData.append('female', female);
    formData.append('children', children);
    formData.append('remark', remark);
    formData.append('payment_mode', payment);
    formData.append('payid', payid);
    formData.append('options', JSON.stringify(options));
    if (fileToUpload1) {
      formData.append('photo_image', fileToUpload1);
    }
    if (fileToUpload2) {
      formData.append('id_image', fileToUpload2);
    }
    formData.append('status', status);
    formData.append('checkout_date', checkoutdate);
    formData.append('checkin_time', checkin_time);
    formData.append('checkout_time', checkout_time);
    formData.append('gstid', gstid);
    if (idimage3) {
      formData.append('idimage3', idimage3);
    }
    formData.append('uniqueId', uniqueId);
    formData.append('bill_no', bill_no);
    formData.append('checkin_no', checkin_no);
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/update-checkin/` + id, formData).pipe(map(data => {
      return data;
    }));
  }


  extraservice(
    checkinid: any,
    phone: any,
    roomnu: any,
    item_name: any,
    quantity: any,
    fromdate: any,
    todate: any,
    totalday: any,
    uniqueId: any) {

    var formData = {
      'checkinid': checkinid,
      'phone': phone,
      'room_no': roomnu,
      'item_name': item_name,
      'quantity': quantity,
      'fromdate': fromdate,
      'todate': todate,
      'totalday': totalday,
      'uniqueId': uniqueId
    }

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/extra-services`, formData).pipe(map(data => {
      return data;
    }));
  }


  foodservice(
    checkinid: any,
    phone: any,
    roomnu: any,
    food_name: any,
    quantity: any,
    rate: any,
    amount: any,
    uniqueId: any) {

    var formData = {
      'checkinid': checkinid,
      'phone': phone,
      'room_no': roomnu,
      'food_name': food_name,
      'quantity': quantity,
      'rate': rate,
      'amount': amount,
      'uniqueId': uniqueId
    }

    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/food-services`, formData).pipe(map(data => {
      return data;
    }));
  }


  laundryservice(
    checkinid: any,
    phone: any,
    roomnu: any,
    laundry_name: any,
    quantity: any,
    rate: any,
    amount: any,
    delivery_date: any,
    delivery_time: any,
    uniqueId: any) {

    var formData = {
      'checkinid': checkinid,
      'phone': phone,
      'room_no': roomnu,
      'laundry_name': laundry_name,
      'quantity': quantity,
      'rate': rate,
      'amount': amount,
      'delivery_date': delivery_date,
      'delivery_time': delivery_time,
      'uniqueId': uniqueId
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/laundry-services`, formData).pipe(map(data => {
      return data;
    }));
  }

  deleteExtra(id: any) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/extra-services/` + id).pipe(map(user => {
      return user;
    }));
  }
  deleteFood(id: any) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/food-services/` + id).pipe(map(user => {
      return user;
    }));
  }
  deleteLaundry(id: any) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/laundry-services/` + id).pipe(map(user => {
      return user;
    }));
  }
  getSingleReservation(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-single-reservation/` + id).pipe(map(user => user));
  }
  //   getInvoice(): Observable<any> {
  //     return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-invoice`).pipe(map(user => user));
  //  }


  //    getSingleInvoice(id: any): Observable<any> {
  //   return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-invoice-details/`+id).pipe(map(user => user));
  // }

  updatereservation(id: any, room_no: any, reservationdate: any,
    phone: any,
    name: any,
    gender: any,
    ardate: any,
    artime: any,
    drdate: any,
    drtime: any,
    address: any, city: any, state: any, country: any, male: any, female: any, children: any, remark: any, options, status: any, uniqueId: any,) {
    var formData = {
      'room_no': room_no,
      'reservation_date': reservationdate,
      'phone': phone,
      'name': name,
      'gender': gender,
      'arrival_date': ardate,
      'arrival_time': artime,
      'departure_date': drdate,
      'departure_time': drtime,
      'address': address,
      'city': city,
      'state': state,
      'country': country,
      'male': male,
      'female': female,
      'children': children,
      'remark': remark,
      'room_details': options,
      'status': status,
      'uniqueId': uniqueId

    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/update-reservation/`, formData).pipe(map(user => {
      return user;
    }));
  }


  getInvoice(params): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-invoice?page=` + params.page + `&search=` + params.search).pipe(map(user => user));
  }


  getSingleInvoice(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-invoice-details/` + id).pipe(map(user => user));
  }


  updatepdf(id: any, pdfdata: String) {
    var formData = {
      'pdf': pdfdata
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/checkout-update/` + id, formData).pipe(map(user => {
      return user;
    }));
  }


  getbokingsss(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/get-bookingss`).pipe(map(user => user));
  }

  updatebookingss(
    id: any,
    fileToUpload1: any,
  ) {
    const formData: FormData = new FormData();
    if (fileToUpload1) {
      formData.append('photo_image', fileToUpload1);
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/checkout-bokingsss/` + id, formData).pipe(map(data => {
      return data;
    }));
  }


  getInstitution(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/superadmin/institutes/displayAll`).pipe(map(user => user));
  }

  saveInsuser(department,institution, name, mobile_number, email, role_id, role_type) {
    var formData = {
      'institute_code': institution,
      'name': name,
      'mobile_number': mobile_number,
      'email': email,
      'role_id': department,
    }
    return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/superadmin/hradmin/add`, formData).pipe(map(user => {
      return user;
    }));
  }

  getInsuser(page, search, role_type): Observable<any> {
    if (!search) {
      search = '';
    }
    return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/superadmin/hradmin/displayAll?search=`+search).pipe(map(user => user));
  }

  deleteUser(id: string) {
    return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/superadmin/hradmin/delete/` + id).pipe(map(user => {
        return user;
    }));
}

}    