import { Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { AlertService, ModalService, RoomService } from '../_services';
import {HomeService} from '../_services';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { DatePipe, formatDate, getLocaleDateTimeFormat } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2'; 
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var jquery: any;
declare var $: any;
declare var alertify: any;

@Component({
  selector: 'app-front-desk',
  templateUrl: './front-desk.component.html',
  styleUrls: ['./front-desk.component.css']
})
export class FrontDeskComponent implements OnInit{
currentdate: any;
roomDate: any;
roomDetails: any;
latest_date: any;
checkindate: any;
room : any;
rmdate: any;
changedate: any;
room_unique_id:any;
reservationRoom$:any;
checkinRoom$:any;
roomDetails$:any;
reservename:any;
reservephone:any;
reservestatus:any;
checkinId:any;
colorroom:any;
makedate = false;
checkin = false;
submitted=false;
checkinData = [];
reservationId = [];
reserve_checkin=false;
chekinusername:any;
checkinphone:any;
checkindetails$:any;
checkinstatus:any;
checkinsid=[];
  constructor(
    private roomSer: RoomService,
    private datepipe: DatePipe,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
    ) { }

  ngOnInit() {



this.currentdate = new Date();
this.latest_date = this.datepipe.transform(this.currentdate, 'yyyy-MM-dd');

this.roomSer.getRoomdate(this.latest_date).subscribe(data => {
    this.roomDate = data.data.dates;
    this.roomDetails = data.data.rooms;
     this.room ='';
    this.room =this.roomDetails;
    this.rmdate ='';
    this.rmdate =this.roomDate;
  })

  }

  
  dialogOpen(e,dt,rm, i){
    e.stopPropagation();

    var selectdate = '';
   selectdate = this.datepipe.transform(dt.date, 'yyyy-MM-dd');
if (selectdate >= this.latest_date) {
 this.makedate = true;
}
else{
  this.makedate = false;
}
if (this.latest_date == selectdate) {
  this.checkin = true;
  this.reserve_checkin=true;
}else{
  this.checkin = false;
  this.reserve_checkin=false;
}

    ////console.log(dt)
    //console.error(dt.room_unique_id)
   this.room ='';
     this.room =dt;
     this.room_unique_id=dt.room_unique_id;
     this.checkinId=dt.checkinId;
   $('.dialog_cls').css('display','none');
   $('.dialog_cls1').css('display','none');
   $('.dialog_cls2').css('display','none');
   

  if(dt.status=='0'){
   $('#window'+dt.room_unique_id).css('display','block');
  }else if(dt.status=='2'){
    var status_txt ='';
    dt.status==0?status_txt='block':status_txt='unblock';
    Swal.fire({
    title: 'Are you sure want to '+ status_txt +' this room?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }).then((result) => {
  if(result.isConfirmed){
   var status = dt.status == 0 ? 2 : 0
  if(dt.status == 0){
  this.roomSer.blockRoom(rm._id,dt.date,status).subscribe(data => {
       dt.status = status;
        Swal.fire(
        'Blocked!',
        'Room has been Blocked',
        'success'
      )
        // location.reload();
     })
    }else if(dt.status == 2){
      this.roomSer.blockRoom1(rm._id,dt.date,dt.room_type,status,dt.checkinId).subscribe(data => {
        dt.status = status;
         Swal.fire(
         'Unblocked!',
         'Room has been Unblocked',
         'success'
       )
     this.roomSer.getRoomdate(this.latest_date).subscribe(data => {
        this.roomDate = data.data.dates;
        this.roomDetails = data.data.rooms;
         this.room ='';
        this.room =this.roomDetails;
        this.rmdate ='';
        this.rmdate =this.roomDate;
      })
         // location.reload();
      })
    }
    }
    else if (result.isDenied) {
      Swal.fire(
        'Cancelled!',
      )
    }
   });
   }
   else if(dt.status=='3'){
    this.colorroom=''; 
    ////console.log(dt)

    console.log(dt)
    this.colorroom=dt.room_no;
  //   this.roomSer.getCheckinSingle(dt.checkinId).subscribe(data => {
  //     this.checkindetails$=data.data && data.data.checkin_details[0]?data.data.checkin_details[0]:'' ;
       this.chekinusername=dt.checkin_name;
   this.checkinphone=dt.phone ;
  // this.checkinstatus=data.data && data.data.checkin_details[0] && data.data.checkin_details[0].status && data.data.checkin_details[0].status ?data.data.checkin_details[0].status:'' ;   
  ////console.log(data)
  this.roomSer.getReservationDetails(dt.phone,dt.status).subscribe(data => {
    ////console.log(data)
    this.checkinRoom$=data.data.rooms;
 })
    // })
    $('#window1'+dt.room_unique_id).css('display','block');
   }
   else if(dt.status=='10'){
    this.colorroom=''; 
     ////console.log(dt)
     this.colorroom=dt.room_no;
    e.stopPropagation();
    // this.roomSer.getCheckinSingle(dt.checkinId).subscribe(data => {
      ////console.log(data)
  //     this.roomDetails$=data.data && data.data.checkin_details[0]?data.data.checkin_details[0]:'' ;
   this.reservename=dt.checkin_name;
  this.reservephone=dt.phone ;
 this.reservestatus=dt.status;
    this.roomSer.getReservationDetails(this.reservephone,this.reservestatus).subscribe(data => {
      ////console.log(data)
      this.reservationRoom$=data.data.rooms;
   })
  // })
    $('#window2'+dt.room_unique_id).css('display','block');
   }
   else if(dt.status=='-1'){
    e.stopPropagation();
    var status_txt ='';
    
   Swal.fire({
   title: 'Are you sure want to remove maintain this room?',
   icon: 'warning',
   showCancelButton: true,
   confirmButtonText: 'Yes',
   cancelButtonText: 'No'
 }).then((result) => {
 if(result.isConfirmed){
  var status = dt.status == -1 ? 0 : -1

 this.roomSer.blockRoom1(rm._id,dt.date,rm.room_type,status,dt.checkinId).subscribe(data => {
      dt.status = status;
       Swal.fire(
       'Maintenance!',
       'Room Maintenance Removed',
       'success'
     )
   this.roomSer.getRoomdate(this.latest_date).subscribe(data => {
      this.roomDate = data.data.dates;
      this.roomDetails = data.data.rooms;
       this.room ='';
      this.room =this.roomDetails;
      this.rmdate ='';
      this.rmdate =this.roomDate;
    })
       // location.reload();
    })
   }
   else if (result.isDenied) {
     Swal.fire(
       'Cancelled!',
     )
   }
  });
  
   }else if(dt.status=='20'){
    var status_txt ='';
    dt.status==0?status_txt='block':status_txt='clean';
    Swal.fire({
    title: 'Are you sure want to '+ status_txt +' this room?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }).then((result) => {
  if(result.isConfirmed){
   var status = dt.status == 0 ? 20 : 0
  if(dt.status == 0){
  this.roomSer.blockRoom(rm._id,dt.date,status).subscribe(data => {
       dt.status = status;
        Swal.fire(
        'Blocked!',
        'Room has been Blocked',
        'success'
      )
        // location.reload();
     })
    }else if(dt.status == 20){
      this.roomSer.blockRoom1(rm._id,dt.date,dt.room_type,status,dt.checkinId).subscribe(data => {
        dt.status = status;
         Swal.fire(
         'Cleaned!',
         'Room has been cleaned',
         'success'
       )
     this.roomSer.getRoomdate(this.latest_date).subscribe(data => {
        this.roomDate = data.data.dates;
        this.roomDetails = data.data.rooms;
         this.room ='';
        this.room =this.roomDetails;
        this.rmdate ='';
        this.rmdate =this.roomDate;
      })
         // location.reload();
      })
    }
    }
    else if (result.isDenied) {
      Swal.fire(
        'Cancelled!',
      )
    }
   });
   }
 
   $(document).click(function(e){
    if(!$(e.target).closest('#show, .dialog_cls').length){
    $('.dialog_cls').hide();
    }
  }) 
  $(document).click(function(e){
    if(!$(e.target).closest('#show, .dialog_cls1').length){
    $('.dialog_cls1').hide(); 
    
    }
  })
  $(document).click(function(e){
    if(!$(e.target).closest('#show, .dialog_cls2').length){
    $('.dialog_cls2').hide();
    }
  }) 
}

editReservation(e,res,dt){

  e.stopPropagation();
  this.router.navigateByUrl("/reservation?id="+res._id);
}
reservationcheckin(e,res){

  e.stopPropagation();
  this.router.navigateByUrl("/checkin?id="+res._id);
}

selectChange(e,res,i)
{
  e.stopPropagation();
e.preventDefault();
  ////console.log(e);
  // alert(res._id)
var that=this;


 
//   $('.case' + i).each(function(){
//     const checkIdExist = that.checkinData.some(val=>val.id==res._id);
//     ////console.log(checkIdExist);
// if ($('.case' + i).is(":checked") )
// {
// isChecked = true
// }

//    if(isChecked && !checkIdExist){
     
   

//       that.checkinData.push({'id':res._id});
//     ////console.log(that.checkinData)
//    } 


//   })
//   ////console.log(that.checkinData)
var that=this;
 
  const checked = e.target.checked;
  if(checked){
  $('.case' + i).prop('checked',true);

       that.reservationId.push({'bookingIds':res._id,'roomId':res.room_no,'uniqueId':res.uniqueId});
      
  
      }else{
 $('.case' +i).prop('checked',false);




const index = that.reservationId.indexOf({'bookingIds':res._id,'roomId':res.room_no,'uniqueId':res.uniqueId});
that.reservationId.splice(index,1)

        
       
      }

      ////console.log(that.reservationId)

  
}
selectChange1(e,res,i)
{
  e.stopPropagation();
e.preventDefault();
  ////console.log(e);
  // alert(res._id)
var that=this;


 
//   $('.case' + i).each(function(){
//     const checkIdExist = that.checkinData.some(val=>val.id==res._id);
//     ////console.log(checkIdExist);
// if ($('.case' + i).is(":checked") )
// {
// isChecked = true
// }

//    if(isChecked && !checkIdExist){
     
   

//       that.checkinData.push({'id':res._id});
//     ////console.log(that.checkinData)
//    } 


//   })
//   ////console.log(that.checkinData)
var that=this;
 
  const checked = e.target.checked;
  if(checked){
  $('.case' + i).prop('checked',true);

       that.checkinsid.push(res.uniqueId);
      
  
      }else{
 $('.case' +i).prop('checked',false);




const index = that.checkinsid.indexOf(res.uniqueId);
that.checkinsid.splice(index,1)

        
       
      }

      ////console.log(that.checkinsid)

  
}





checkDiv(e,res){
  e.stopPropagation();

}

editcheckininfo(dt,res,chk){
////console.log(res.price)
 ////console.log(chk)

 this.router.navigateByUrl('/edit-checkin?id='+chk._id+'&uniqueid='+chk.uniqueId);

}
extraservice(dt,chk){
  ////console.log(dt)
 
  this.router.navigateByUrl('/extra-service?id='+chk._id);

}

foodservice(dt,chk){
  ////console.log(dt)
 
  this.router.navigateByUrl('/food-service?id='+chk._id);

}
laundryservice(dt,chk){
  ////console.log(dt)
 
  this.router.navigateByUrl('/laundry-service?id='+chk._id);

}
checkout(dt,chk){
  ////console.log(dt)
 
  this.router.navigateByUrl('/checkout?id='+chk._id+'&checkout_date='+chk.checkout_date);

}

dateChange(e){
this.changedate = '';
this.changedate = e.target.value;
 this.roomSer.getRoomdate(this.changedate).subscribe(data => {
    this.roomDate = data.data.dates;
    this.roomDetails = data.data.rooms;
    //////console.log(this.roomDetails[0].dates)
    });
}

cancelreserve(e)
{

  e.stopPropagation();
  if(!this.reservationId.length)
  {
    this.toastr.error("please select reservation")
    return false;
  }


  Swal.fire({
    title: 'Are you sure want to Cancel Reservation?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }).then((result) => {
  if(result.isConfirmed){
 
 
  this.roomSer.cancelreservation(this.reservationId).subscribe(data => {
      
        Swal.fire(
        'cancellation successful'
      )
    this.roomSer.getRoomdate(this.latest_date).subscribe(data => {
        this.roomDate = data.data.dates;
        this.roomDetails = data.data.rooms;
         this.room ='';
        this.room =this.roomDetails;
        this.rmdate ='';
        this.rmdate =this.roomDate;
      })
        // location.reload();
     })
    }
    else if (result.isDenied) {
      Swal.fire(
        'Cancelled!',
      )
    }
   });

}

cancelCheckins(e)
{

  e.stopPropagation();
  if(!this.checkinsid.length)
  {
    this.toastr.error("please select Checkin")
    return false;
  }


  Swal.fire({
    title: 'Are you sure want to Cancel Checkin?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }).then((result) => {
  if(result.isConfirmed){
 
 
  this.roomSer.cancelcheckin(this.checkinsid).subscribe(data => {
      
        Swal.fire(
        'cancellation successful'
      )
    this.roomSer.getRoomdate(this.latest_date).subscribe(data => {
        this.roomDate = data.data.dates;
        this.roomDetails = data.data.rooms;
         this.room ='';
        this.room =this.roomDetails;
        this.rmdate ='';
        this.rmdate =this.roomDate;
      })
        // location.reload();
     })
    }
    else if (result.isDenied) {
      Swal.fire(
        'Cancelled!',
      )
    }
   });

}




check(e,rmd,dt){
  this.router.navigateByUrl('/checkin?checkin_date='+dt.date+'&room_no='+rmd._id);
}


reservationDate(e,rmd,dt){
  this.router.navigateByUrl('/reservation?reserve_date='+dt.date+'&room_no='+rmd._id);
}



blockPopup(e,dt,rm){
  this.rmdate ='';
    this.rmdate =dt;
  e.stopPropagation();
  var status_txt ='';
  dt.status==0?status_txt='block':status_txt='unblock';
  Swal.fire({
  title: 'Are you sure want to '+ status_txt +' this room?',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No'
}).then((result) => {
if(result.isConfirmed){
 var status = dt.status == 0 ? 2 : 0
if(dt.status == 0){
this.roomSer.blockRoom(rm._id,dt.date,status).subscribe(data => {
     dt.status = status;
      Swal.fire(
      'Blocked!',
      'Room has been Blocked',
      'success'
    )
  this.roomSer.getRoomdate(this.latest_date).subscribe(data => {
      this.roomDate = data.data.dates;
      this.roomDetails = data.data.rooms;
       this.room ='';
      this.room =this.roomDetails;
      this.rmdate ='';
      this.rmdate =this.roomDate;
    })
      // location.reload();
   })
  }else if(dt.status == 2){
    this.roomSer.blockRoom1(rm._id,dt.date,dt.room_type,status,dt.checkinId).subscribe(data => {
      dt.status = status;
       Swal.fire(
       'Blocked!',
       'Room has been Blocked',
       'success'
     )
       // location.reload();
    })
  }
  }
  else if (result.isDenied) {
    Swal.fire(
      'Cancelled!',
    )
  }
 });
}


maintenancePopup(e,dt,rm){
   this.rmdate ='';
    this.rmdate =rm;
  e.stopPropagation();
   var status_txt ='';
  Swal.fire({
  title: 'Are you sure want to maintain this room?',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes',
  cancelButtonText: 'No'
}).then((result) => {
if(result.isConfirmed){
 var status = dt.status == -1 ? 0 : -1

this.roomSer.blockRoom(rm._id,dt.date,status).subscribe(data => {
     dt.status = status;
      Swal.fire(
      'Maintenance!',
      'Room is in Maintenance',
      'success'
    )
  this.roomSer.getRoomdate(this.latest_date).subscribe(data => {
      this.roomDate = data.data.dates;
      this.roomDetails = data.data.rooms;
       this.room ='';
      this.room =this.roomDetails;
      this.rmdate ='';
      this.rmdate =this.roomDate;
    })
      // location.reload();
   })
  }
  else if (result.isDenied) {
    Swal.fire(
      'Cancelled!',
    )
  }
 });
}

CanceRoompopup(e,dt,rm){
  this.rmdate ='';
   this.rmdate =rm;
 e.stopPropagation();
  var status_txt ='';
 Swal.fire({
 title: 'Are you sure want to Cancel this room?',
 icon: 'warning',
 showCancelButton: true,
 confirmButtonText: 'Yes',
 cancelButtonText: 'No'
}).then((result) => {
if(result.isConfirmed){
var status = dt.status == 3 ? 0 : 3

this.roomSer.blockRoom1(rm._id,dt.date,rm.room_type,status,dt.checkinId).subscribe(data => {
    dt.status = status;
     Swal.fire(
     'Room Canceled!',
     'Room Canceled successfully',
     'success'
   )
 this.roomSer.getRoomdate(this.latest_date).subscribe(data => {
    this.roomDate = data.data.dates;
    this.roomDetails = data.data.rooms;
     this.room ='';
    this.room =this.roomDetails;
    this.rmdate ='';
    this.rmdate =this.roomDate;
  })
    //  location.reload();
  })
 }
 else if (result.isDenied) {
   Swal.fire(
     'Cancelled!',
   )
 }
});
}


   ngAfterViewInit(){
    $(document).on('focus','input,select,textarea',function(e){ 
      var cur = $(this);
      $('input,select,textarea').parent().removeClass('is-focused');
      cur.parent()[0].classList.remove('is-focused');
      
      // cur.parent()[0].removeClass('is-focused')
      
        cur.parent()[0].classList.add('is-focused')
       })
  
    var tableOffset = $("#table-1").offset().top;
var $header = $("#table-1 > thead").clone();
var $fixedHeader = $("#header-fixed").append($header);

$(window).bind("scroll", function() {
    var offset = $(this).scrollTop();
    
    if (offset >= tableOffset && $fixedHeader.is(":hidden")) {
        $fixedHeader.show();
    }
    else if (offset < tableOffset) {
        $fixedHeader.hide();
    }
});
  }


  
}
